const requireContext = require.context('./client', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/transaction-management/clients',
  loading: false,
  permissions: [],
  clients: [],
  meta: {},
  detail: {},
  assignedAgents: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_CLIENTS(state, payload) {
    state.clients = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_ASSIGNED_AGENTS(state, payload) {
    state.assignedAgents = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.clients = [];
    state.meta = {};
    state.detail = {};
    state.assignedAgents = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/clients/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_CLIENTS', result.clients.data);
      commit('SET_META', result.clients.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/clients/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_CLIENTS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState, dispatch }, uuid) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/clients/' + uuid + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result);
      commit('SET_ASSIGNED_AGENTS', result.assigned_clients);
      await dispatch('client/form/restoreInitialState', {}, { root: true });
      await dispatch('client/form/applyBaseData', result.form.base_data, { root: true });
      await dispatch('client/form/applyEditDataFromDetail', result.form.edit_data, { root: true });
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
