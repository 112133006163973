const requireContext = require.context('./banner', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/banner-management/banners',
  loading: false,
  permissions: [],
  banners: [],
  meta: {},
  bannerTypeFilters: [],
  availabilityStatusFilters: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_BANNERS(state, payload) {
    state.banners = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_BANNER_TYPE_FILTERS(state, payload) {
    state.bannerTypeFilters = payload;
  },
  SET_AVAILABILITY_STATUS_FILTERS(state, payload) {
    state.availabilityStatusFilters = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.banners = [];
    state.meta = {};
    state.bannerTypeFilters = [];
    state.availabilityStatusFilters = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      console.log('AGENT BANNER QUERY: ', query);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/banners/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_BANNERS', result.banners.data);
      commit('SET_META', result.banners.meta);
      commit('SET_BANNER_TYPE_FILTERS', result.type_filters);
      commit('SET_AVAILABILITY_STATUS_FILTERS', result.availability_status_filters);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async delete({ rootState }, uuid) {
    await this._vm.$http.post('/api/banners/delete', {
      uuid,
      url: rootState.route.path.substring(1),
    });
  },
};
