export const state = () => ({
  formType: null,
  loading: false,
  id: null,
  name: null,
  projectName: null,
  projectUuid: null,
});

export const getters = {
  formData(state) {
    return {
      id: state.id,
      name: state.name,
      project_uuid: state.projectUuid,
    };
  },
};

export const mutations = {
  SET_PROJECT_NAME(state, payload) {
    state.projectName = payload;
  },
  SET_ID(state, payload) {
    state.id = payload;
  },
  SET_PROJECT_UUID(state, uuid) {
    state.projectUuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
};

export const actions = {
  async postData({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/unit_categories/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_ID', responseData.id);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async applyEditData({ commit }, data) {
    commit('SET_ID', data.id);
    commit('SET_NAME', data.name);
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/unit_categories/add', {
          params: {
            url: rootState.route.path.substring(1),
            project_uuid: state.projectUuid,
          },
        });
        commit('SET_PROJECT_NAME', resultData.project_name);
      } else {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/unit_categories/edit/' + state.id, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData);
        await commit('SET_PROJECT_NAME', resultData.project_name);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  restoreInitialState({ commit }) {
    commit('SET_PROJECT_NAME', null);
    commit('SET_ID', null);
    commit('SET_PROJECT_UUID', null);
    commit('SET_NAME', null);
  },
};
