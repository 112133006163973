const requireContext = require.context('./promo', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/promo-management/promos',
  loading: false,
  permissions: [],
  promos: [],
  meta: {},
  detail: {},
  activeFilters: ['AKTIF', 'TIDAK AKTIF'],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_PROMOS(state, payload) {
    state.promos = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.promos = [];
    state.meta = {};
    state.detail = {};
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/promos/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_PROMOS', result.promos.data);
      commit('SET_META', result.promos.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      let params = generateParams(rootState, query);
      if (params.is_active) {
        if (params.is_active === 'AKTIF') {
          params.is_active = true;
        } else if (params.is_active === 'TIDAK AKTIF') {
          params.is_active = false;
        } else {
          delete params.is_active;
        }
      }
      const data = await this._vm.$http.get('/api/promos/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_PROMOS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async delete({ rootState }, uuid) {
    await this._vm.$http.post('/api/promos/delete', {
      uuid,
      url: rootState.route.path.substring(1),
    });
  },
};
