export const state = () => ({
  basePath: '/registration-management/agents',
  loading: false,
  permissions: [],
  agents: [],
  meta: {},
  detail: {},
  filterStatuses: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_AGENTS(state, payload) {
    state.agents = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_FILTER_STATUSES(state, payload) {
    state.filterStatuses = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.agents = [];
    state.meta = {};
    state.detail = {};
    state.filterStatuses = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/agent_regs/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_AGENTS', result.agents.data);
      commit('SET_META', result.agents.meta);
      commit('SET_FILTER_STATUSES', result.filter_statuses);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState }, id) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/agent_regs/' + id + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result.agent);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async accept({ rootState }, id) {
    await this._vm.$http.post('/api/agent_regs/accept', {
      registration_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async reject({ rootState }, id) {
    await this._vm.$http.post('/api/agent_regs/reject', {
      registration_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async pending({ rootState }, id) {
    await this._vm.$http.post('/api/agent_regs/pending', {
      registration_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async export({ rootState }) {
    const response = await this._vm.$http.get('/api/agent_regs/export_excel', {
      params: {
        url: rootState.route.path.substring(1),
      },
    });
    const link = document.createElement('a');
    link.href = response.data.data;
    const contentDisposition = response.headers['content-disposition'];
    let currentDate = new Date(Date.now());
    let fileName = `Registrasi Agen - ${currentDate.toDateString()}`;
    // let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};
