const requireContext = require.context('./agentMember', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/user-management/agent-members',
  loading: false,
  permissions: [],
  users: [],
  cities: [],
  meta: {},
  detail: null,
  viewListingPermissions: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_VIEW_LISTING_PERMISSIONS(state, payload) {
    state.viewListingPermissions = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.users = [];
    state.cities = [];
    state.meta = {};
    state.detail = null;
    state.viewListingPermissions = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/agent_members/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_USERS', result.users.data);
      commit('SET_CITIES', result.cities);
      commit('SET_META', result.users.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/agent_members/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_USERS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState, dispatch }, uuid) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/agent_members/' + uuid + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result);
      commit('SET_VIEW_LISTING_PERMISSIONS', data.data.meta.data_entry_listing_permissions);
      await dispatch('agentMember/form/restoreInitialState', {}, { root: true });
      await dispatch('agentMember/form/applyBaseData', result, { root: true });
      await dispatch('agentMember/form/applyEditData', result, {
        root: true,
      });
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
