export const state = () => ({
  formType: null,
  loading: false,
  promoUuid: null,
  promoCode: null,
  selectedSales: null,
  qty: null,
  isActive: false,
  discountPercentage: null,
  discountAmount: null,
  purchaseLimitPerUser: null,
  allowedEmails: [],
  requirements: [],
  salesList: [],
  membershipTypes: [],
  productTypes: [],
  renewalTypes: [],
});

export const getters = {
  formData(state) {
    return {
      uuid: state.promoUuid,
      promo_code: state.promoCode,
      sales_user_uuid: state.selectedSales ? state.selectedSales.uuid : null,
      qty: state.qty,
      is_active: state.isActive,
      discount_percentage: state.discountPercentage,
      discount_amount: state.discountAmount,
      purchase_limit_per_user: state.purchaseLimitPerUser,
      allowed_emails: state.allowedEmails,
      requirements: state.requirements.map((value) => {
        return {
          membership_type_id: value.membership ? value.membership.id : null,
          product_type_id: value.product ? value.product.id : null,
          renewal_type: value.renewalType ? value.renewalType.id : null,
          min_qty: value.minQty ? value.minQty : null,
          min_duration: value.minDuration ? value.minDuration : null,
        };
      }),
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_PROMO_UUID(state, val) {
    state.promoUuid = val;
  },
  SET_PROMO_CODE(state, val) {
    state.promoCode = val;
  },
  SET_SELECTED_SALES(state, val) {
    state.selectedSales = val;
  },
  SET_QTY(state, val) {
    state.qty = val;
  },
  SET_IS_ACTIVE(state, val) {
    state.isActive = val;
  },
  SET_DISCOUNT_PERCENTAGE(state, val) {
    state.discountPercentage = val;
  },
  SET_DISCOUNT_AMOUNT(state, val) {
    state.discountAmount = val;
  },
  SET_PURCHASE_LIMIT_PER_USER(state, val) {
    state.purchaseLimitPerUser = val;
  },

  SET_ALLOWED_EMAILS(state, values) {
    let temp = [];
    values.forEach(function (element) {
      temp.push(element);
    });
    state.allowedEmails = temp;
  },
  ADD_ALLOWED_EMAILS(state) {
    state.allowedEmails.push('');
  },
  REMOVE_ALLOWED_EMAILS(state, index) {
    if (state.allowedEmails.length > 0) {
      state.allowedEmails.splice(index, 1);
    }
  },
  INPUT_EMAIL(state, { index, email }) {
    state.allowedEmails[index] = email;
  },

  SET_REQUIREMENTS(state, values) {
    let temp = [];
    values.forEach(function (element) {
      temp.push(element);
    });
    state.requirements = temp;
  },
  ADD_REQUIREMENTS(state) {
    state.requirements.push({ membership: null, product: null });
  },
  REMOVE_REQUIREMENTS(state, index) {
    if (state.requirements.length > 0) {
      state.requirements.splice(index, 1);
    }
  },
  INPUT_REQUIREMENT_MEMBERSHIP(state, { index, membership }) {
    let requirement = JSON.parse(JSON.stringify(state.requirements))[index];
    requirement.membership = membership;
    state.requirements.splice(index, 1, requirement);
  },
  INPUT_REQUIREMENT_PRODUCT(state, { index, product }) {
    let requirement = JSON.parse(JSON.stringify(state.requirements))[index];
    requirement.product = product;
    state.requirements.splice(index, 1, requirement);
  },
  INPUT_REQUIREMENT_RENEWAL_TYPE(state, { index, renewalType }) {
    let requirement = JSON.parse(JSON.stringify(state.requirements))[index];
    requirement.renewalType = renewalType;
    state.requirements.splice(index, 1, requirement);
  },

  INPUT_REQUIREMENT_MIN_QTY(state, { index, minQty }) {
    let requirement = JSON.parse(JSON.stringify(state.requirements))[index];
    requirement.minQty = minQty;
    state.requirements.splice(index, 1, requirement);
  },

  INPUT_REQUIREMENT_MIN_DURATION(state, { index, minDuration }) {
    let requirement = JSON.parse(JSON.stringify(state.requirements))[index];
    requirement.minDuration = minDuration;
    state.requirements.splice(index, 1, requirement);
  },
  SET_SALES_LIST(state, val) {
    state.salesList = val;
  },
  SET_MEMBERSHIP_TYPES(state, val) {
    state.membershipTypes = val;
  },
  SET_PRODUCT_TYPES(state, val) {
    state.productTypes = val;
  },
  SET_RENEWAL_TYPES(state, val) {
    state.renewalTypes = val;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/promos/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_PROMO_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async applyBaseData({ commit }, data) {
    commit('SET_SALES_LIST', data.sales_list);

    let membershipTypes = [];
    for (let index in data.membership_types) {
      membershipTypes.push({ id: index, name: data.membership_types[index] });
    }
    commit('SET_MEMBERSHIP_TYPES', membershipTypes);

    let productTypes = [];
    for (let index in data.product_types) {
      productTypes.push({ id: index, name: data.product_types[index] });
    }
    commit('SET_PRODUCT_TYPES', productTypes);
    commit('SET_RENEWAL_TYPES', [
      {
        id: 'early_bird',
        name: 'Early Bird',
      },
      {
        id: 'regular',
        name: 'Regular',
      },
    ]);
  },
  async applyEditData({ state, commit }, data) {
    commit('SET_PROMO_UUID', data.uuid);
    commit('SET_PROMO_CODE', data.promo_code);
    commit('SET_QTY', data.qty);
    commit('SET_IS_ACTIVE', data.is_active);
    commit('SET_DISCOUNT_PERCENTAGE', data.discount_percentage);
    commit('SET_DISCOUNT_AMOUNT', data.discount_amount);
    commit('SET_PURCHASE_LIMIT_PER_USER', data.purchase_limit_per_user);
    commit('SET_ALLOWED_EMAILS', data.allowed_emails);

    if (data.sales_user_uuid) {
      const selected = state.salesList.find((item) => {
        return item.uuid == data.sales_user_uuid;
      });
      commit('SET_SELECTED_SALES', selected ? selected : null);
    } else {
      commit('SET_SELECTED_SALES', null);
    }

    if (data.requirements) {
      let requirements = [];
      for (let req of data.requirements) {
        const membership = state.membershipTypes.find((item) => {
          return item.id === req.membership_type_id + '';
        });
        const product = state.productTypes.find((item) => {
          return item.id === req.product_type_id + '';
        });
        const renewalType = state.renewalTypes.find((item) => {
          if (req.is_early_bird) {
            return item.id === 'early_bird';
          } else {
            return item.id === 'regular';
          }
        });
        requirements.push({
          membership: membership,
          product: product,
          renewalType: renewalType,
          minQty: req.min_qty,
          minDuration: req.min_duration,
        });
      }
      commit('SET_REQUIREMENTS', requirements);
    } else {
      commit('SET_REQUIREMENTS', []);
    }
  },
  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/promos/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/promos/edit/' + state.promoUuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_PROMO_UUID', null);
    commit('SET_PROMO_CODE', null);
    commit('SET_QTY', null);
    commit('SET_IS_ACTIVE', false);
    commit('SET_DISCOUNT_PERCENTAGE', null);
    commit('SET_DISCOUNT_AMOUNT', null);
    commit('SET_PURCHASE_LIMIT_PER_USER', null);
    commit('SET_ALLOWED_EMAILS', []);
    commit('SET_REQUIREMENTS', []);
    commit('SET_SALES_LIST', []);
    commit('SET_MEMBERSHIP_TYPES', []);
    commit('SET_PRODUCT_TYPES', []);
    commit('SET_RENEWAL_TYPES', []);
  },
};
