<template>
  <div>
    <v-autocomplete
      v-model="selectedItem"
      :items="items"
      :label="label"
      :multiple="multiple"
      :item-text="itemTitle"
      :item-value="itemValue"
      hide-details
      clearable
      solo
      style="width: 400px"
      :no-filter="!searchable"
    >
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'DropdownFilter',
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemTitle: {
      type: String,
      required: false,
      default: null,
    },
    itemValue: {
      type: String,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Filter',
    },
  },
  data: () => ({
    selectedItem: null,
  }),
  watch: {
    selectedItem(val) {
      this.changeFilter(val);
    },
  },
  methods: {
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
