const requireContext = require.context('./billing', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/transaction-management/billings',
  loading: false,
  permissions: [],
  detail: {},
  agent: {},
  owner: {},
  client: {},
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_AGENT(state, payload) {
    state.agent = payload;
  },
  SET_OWNER(state, payload) {
    state.owner = payload;
  },
  SET_CLIENT(state, payload) {
    state.client = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.meta = {};
    state.detail = {};
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getDetailInitData({ commit, rootState, dispatch }, uuid) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/billings/' + uuid + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result);
      commit('SET_AGENT', result.agent);
      commit('SET_OWNER', result.listing_owner_client);
      commit('SET_CLIENT', result.payer_client);
      dispatch('billing/form/applyBaseData', result, { root: true });
      dispatch('billing/form/applyEditData', result, { root: true });
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
