export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  transactionUuid: null,
  amount: null,
  billingTypes: [],
  selectedBillingType: null,
});

export const getters = {
  createFormData(state) {
    return {
      transaction_uuid: state.transactionUuid,
      amount: state.amount,
      billing_type_id: state.selectedBillingType != null ? state.selectedBillingType.id : null,
    };
  },
  updateFormData(state) {
    return {
      billing_uuid: state.uuid,
      amount: state.amount,
      billing_type_id: state.selectedBillingType != null ? state.selectedBillingType.id : null,
    };
  },
  statusFormData(state) {
    return {
      billing_uuid: state.uuid,
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_TRANSACTION_UUID(state, payload) {
    state.transactionUuid = payload;
  },
  SET_AMOUNT(state, payload) {
    state.amount = payload;
  },
  SET_BILLING_TYPES(state, payload) {
    state.billingTypes = payload;
  },
  SET_SELECTED_BILLING_TYPE(state, payload) {
    state.selectedBillingType = payload;
  },
};

export const actions = {
  async create({ getters, dispatch }) {
    let formData = getters.createFormData;
    const url = '/api/billings/create';
    await dispatch('postData', { url: url, formData: formData });
  },
  async update({ getters, dispatch }) {
    let formData = getters.updateFormData;
    const url = '/api/billings/update';
    await dispatch('postData', { url: url, formData: formData });
  },
  async delete({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/billings/delete';
    await dispatch('postData', { url: url, formData: formData });
  },
  async acceptPaymentProof({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/billings/accept_payment_proof';
    await dispatch('postData', { url: url, formData: formData });
  },
  async rejectPaymentProof({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/billings/reject_payment_proof';
    await dispatch('postData', { url: url, formData: formData });
  },
  async postData({ commit, rootState }, { url, formData }) {
    try {
      commit('SET_LOADING', true);
      formData.url = rootState.route.path.substring(1);

      await this._vm.$http.post(url, formData);
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  applyBaseData({ commit }, baseData) {
    commit('SET_BILLING_TYPES', baseData.billing_types);
  },

  async applyEditData({ commit, state }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_TRANSACTION_UUID', data.transaction_uuid);
    commit('SET_AMOUNT', data.amount);

    if (data.billing_type_id) {
      const selected = state.billingTypes.find((item) => {
        return item.id == data.billing_type_id;
      });
      commit('SET_SELECTED_BILLING_TYPE', selected ? selected : null);
    }
  },

  restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_TRANSACTION_UUID', null);
    commit('SET_AMOUNT', null);
    commit('SET_BILLING_TYPES', []);
    commit('SET_SELECTED_BILLING_TYPE', null);
  },
};
