/**
 * This plugin create an axios HTTP client to do request.
 * It handles tokens too to acess to private routes on API.
 */

import axios from 'axios';
import swal from 'sweetalert2';
import Vue from 'vue';

export default (store, router) => {
  axios.defaults.headers.common['Content-Language'] = store.state.global.locale;
  axios.defaults.headers.common.Authorization = `Bearer ${store.state.global.token}`;
  axios.defaults.headers.common['time-zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const httpClient = axios.create({
    // Change API url: depends on server side or client side
    baseURL: store.state.global.apiUrl,
  });
  // Use response interceptor
  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('ERROR HTTP:', error);
      const { status } = error.response || {};
      if (status >= 500) {
        swal({
          type: 'error',
          title: 'Oops...',
          text: error.response.data.error,
          reverseButtons: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        });
      } else if (status === 470) {
        // validation exception
        const errors = error.response.data.errors;
        store.commit('error/SET_ERROR', errors);
      } else if (status === 401 && store.getters.isLoggedIn) {
        swal({
          type: 'warning',
          title: 'Session Expired',
          text: 'Please Login to continue',
          reverseButtons: true,
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        }).then(() => {
          store.dispatch('logout');

          router.push('/login');
        });
      } else {
        if (error.response.data.errors) {
          swal({
            type: 'error',
            title: 'Oops...',
            text: error.response.data.errors,
            reverseButtons: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'CANCEL',
          });
        }
      }

      return Promise.reject(error);
    },
  );
  Vue.prototype.$http = httpClient;
};
