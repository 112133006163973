export const state = () => ({
  basePath: '/registration-management/referral-codes',
  loading: false,
  permissions: [],
  referredUsers: [],
  meta: {},
  detail: {},
  registrants: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_REFERRED_USERS(state, payload) {
    state.referredUsers = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_REGISTRANTS(state, payload) {
    state.registrants = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.referredUsers = [];
    state.meta = {};
    state.detail = {};
    state.registrants = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/referral_codes/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_REFERRED_USERS', result.referred_users.data);
      commit('SET_META', result.referred_users.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState }, uuid) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/referral_codes/' + uuid + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_REGISTRANTS', result.registrants);
      commit('SET_DETAIL', result);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async accept({ rootState }, id) {
    await this._vm.$http.post('/api/referral_codes/accept', {
      referral_registrant_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async reject({ rootState }, id) {
    await this._vm.$http.post('/api/referral_codes/reject', {
      referral_registrant_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async claim({ rootState }, id) {
    await this._vm.$http.post('/api/referral_codes/claim', {
      referral_registrant_id: id,
      url: rootState.route.path.substring(1),
    });
  },
};
