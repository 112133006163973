export const state = () => ({
  loading: false,
  agents: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_AGENTS(state, payload) {
    state.agents = payload;
  },
  RESET_DEFAULT(state) {
    state.agents = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getList({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      console.log('query', query);
      const params = generateParams(rootState, query);
      console.log('params get list', params);
      const data = await this._vm.$http.get('/api/agents/get_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_AGENTS', result);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
