import { getFieldRule } from '@/utils/listingFormUtils';

export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  floor: null,
  unitBlock: null,
  unitNumber: null,
  landSize: null,
  buildingSize: null,
  bathroom: null,
  bedroom: null,
  price: null,
  isActive: true,
  isSold: false,

  unitCategories: [],
  selectedUnitCategory: null,
  unitTypes: [],
  selectedUnitType: null,
  buildingDirections: [],
  selectedBuildingDirection: null,
  floorTypes: [],
  selectedFloorType: null,
  projectName: null,
  projectUuid: null,

  allowFloor: true,
  allowUnitBlock: true,
  allowLandSize: true,

  fieldRules: [],
  selectedPropertyType: null,
});

export const getters = {
  formData(state) {
    return {
      uuid: state.uuid,
      floor: state.floor,
      unit_block: state.unitBlock,
      unit_number: state.unitNumber,
      price: state.price,
      is_active: state.isActive,
      is_sold: state.isSold,
      land_size: state.landSize,
      building_size: state.buildingSize,
      bathroom: state.bathroom,
      bedroom: state.bedroom,
      unit_category_id: state.selectedUnitCategory ? state.selectedUnitCategory.id : null,
      project_unit_type_uuid: state.selectedUnitType ? state.selectedUnitType.uuid : null,
      building_direction_id: state.selectedBuildingDirection
        ? state.selectedBuildingDirection.id
        : null,
      floor_type_id: state.selectedFloorType ? state.selectedFloorType.id : null,
    };
  },

  unitBlockRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'UNIT_BLOCK');
  },
  landSizeRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'LAND_SIZE');
  },
  buildingSizeRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'BUILDING_SIZE');
  },
  bathroomRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'BATHROOM');
  },
  bedroomRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'BEDROOM');
  },
};

export const mutations = {
  SET_ALLOW_FLOOR(state, uuid) {
    state.allowFloor = uuid;
  },
  SET_ALLOW_LAND_SIZE(state, uuid) {
    state.allowLandSize = uuid;
  },
  SET_ALLOW_UNIT_BLOCK(state, uuid) {
    state.allowUnitBlock = uuid;
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_PROJECT_UUID(state, uuid) {
    state.projectUuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_FLOOR(state, payload) {
    state.floor = payload;
  },
  SET_LAND_SIZE(state, payload) {
    state.landSize = payload;
  },
  SET_BUILDING_SIZE(state, payload) {
    state.buildingSize = payload;
  },
  SET_BATHROOM(state, payload) {
    state.bathroom = payload;
  },
  SET_BEDROOM(state, payload) {
    state.bedroom = payload;
  },
  SET_UNIT_BLOCK(state, payload) {
    state.unitBlock = payload;
  },
  SET_UNIT_NUMBER(state, payload) {
    state.unitNumber = payload;
  },
  SET_PRICE(state, payload) {
    state.price = payload;
  },
  SET_IS_ACTIVE(state, payload) {
    state.isActive = payload;
  },
  SET_IS_SOLD(state, payload) {
    state.isSold = payload;
  },
  SET_SELECTED_UNIT_CATEGORY(state, payload) {
    state.selectedUnitCategory = payload;
  },
  SET_SELECTED_UNIT_TYPE(state, payload) {
    state.selectedUnitType = payload;
  },
  SET_SELECTED_BUILDING_DIRECTION(state, payload) {
    state.selectedBuildingDirection = payload;
  },
  SET_SELECTED_FLOOR_TYPE(state, payload) {
    state.selectedFloorType = payload;
  },
  SET_PROJECT_NAME(state, payload) {
    state.projectName = payload;
  },
  SET_UNIT_CATEGORIES(state, payload) {
    state.unitCategories = payload;
  },
  SET_UNIT_TYPES(state, payload) {
    state.unitTypes = payload;
  },
  SET_BUILDING_DIRECTIONS(state, payload) {
    state.buildingDirections = payload;
  },
  SET_FLOOR_TYPES(state, payload) {
    state.floorTypes = payload;
  },
  SET_FIELD_RULES(state, payload) {
    state.fieldRules = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
};

export const actions = {
  async postData({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/project_units/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  applyBaseData({ commit }, baseData) {
    commit('SET_UNIT_CATEGORIES', baseData.unit_categories);
    commit('SET_UNIT_TYPES', baseData.project_unit_types);
    commit('SET_BUILDING_DIRECTIONS', baseData.building_directions);
    commit('SET_FLOOR_TYPES', baseData.floor_types);
    commit('SET_FIELD_RULES', baseData.field_rules);
    commit('SET_SELECTED_PROPERTY_TYPE', {
      id: baseData.property_type_id,
      name: '',
    });
  },

  async applyEditData({ commit, state }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_FLOOR', data.floor);
    commit('SET_UNIT_BLOCK', data.unit_block);
    commit('SET_UNIT_NUMBER', data.unit_number);
    commit('SET_PRICE', data.price);
    commit('SET_IS_ACTIVE', data.is_active);
    commit('SET_IS_SOLD', data.is_sold);
    commit('SET_LAND_SIZE', data.land_size);
    commit('SET_BUILDING_SIZE', data.building_size);
    commit('SET_BATHROOM', data.bathroom);
    commit('SET_BEDROOM', data.bedroom);

    if (data.unit_category_id) {
      const selectedUnitCategory = state.unitCategories.find((item) => {
        return item.id == data.unit_category_id;
      });
      commit('SET_SELECTED_UNIT_CATEGORY', selectedUnitCategory ? selectedUnitCategory : null);
    }
    if (data.project_unit_type_uuid) {
      const selectedUnitType = state.unitTypes.find((item) => {
        return item.uuid == data.project_unit_type_uuid;
      });
      commit('SET_SELECTED_UNIT_TYPE', selectedUnitType ? selectedUnitType : null);
    }
    if (data.building_direction_id) {
      const selectedBuildingDirection = state.buildingDirections.find((item) => {
        return item.id == data.building_direction_id;
      });
      commit(
        'SET_SELECTED_BUILDING_DIRECTION',
        selectedBuildingDirection ? selectedBuildingDirection : null,
      );
    }
    if (data.floor_type_id) {
      const selectedFloorType = state.floorTypes.find((item) => {
        return item.id == data.floor_type_id;
      });
      commit('SET_SELECTED_FLOOR_TYPE', selectedFloorType ? selectedFloorType : null);
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      console.log('fetch base', params);
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/project_units/add', {
          params: {
            url: rootState.route.path.substring(1),
            project_uuid: state.projectUuid,
            unit_category_id: params.unit_category_id,
            project_unit_type_uuid: params.project_unit_type_uuid,
          },
        });
        await dispatch('applyBaseData', resultData);
        commit('SET_PROJECT_NAME', resultData.project_name);
        commit('SET_ALLOW_FLOOR', resultData.allow_floor);
        commit('SET_ALLOW_UNIT_BLOCK', resultData.allow_unit_block);
        commit('SET_ALLOW_LAND_SIZE', resultData.allow_land_size);

        if (params.unit_category_id) {
          const selectedUnitCategory = state.unitCategories.find((item) => {
            return item.id == params.unit_category_id;
          });
          commit('SET_SELECTED_UNIT_CATEGORY', selectedUnitCategory ? selectedUnitCategory : null);
        }
        if (params.project_unit_type_uuid) {
          const selectedUnitType = state.unitTypes.find((item) => {
            return item.uuid == params.project_unit_type_uuid;
          });
          commit('SET_SELECTED_UNIT_TYPE', selectedUnitType ? selectedUnitType : null);
        }
      } else {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/project_units/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
        commit('SET_PROJECT_NAME', resultData.project_name);
        commit('SET_ALLOW_FLOOR', resultData.allow_floor);
        commit('SET_ALLOW_UNIT_BLOCK', resultData.allow_unit_block);
        commit('SET_ALLOW_LAND_SIZE', resultData.allow_land_size);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  restoreInitialState({ commit }) {
    commit('SET_ALLOW_FLOOR', true);
    commit('SET_ALLOW_UNIT_BLOCK', true);
    commit('SET_ALLOW_LAND_SIZE', true);
    commit('SET_UUID', null);
    commit('SET_FLOOR', null);
    commit('SET_UNIT_BLOCK', null);
    commit('SET_UNIT_NUMBER', null);
    commit('SET_PRICE', null);
    commit('SET_LAND_SIZE', null);
    commit('SET_BUILDING_SIZE', null);
    commit('SET_BATHROOM', null);
    commit('SET_BEDROOM', null);
    commit('SET_IS_ACTIVE', true);
    commit('SET_IS_SOLD', false);
    commit('SET_SELECTED_UNIT_CATEGORY', null);
    commit('SET_SELECTED_UNIT_TYPE', null);
    commit('SET_SELECTED_BUILDING_DIRECTION', null);
    commit('SET_SELECTED_FLOOR_TYPE', null);
    commit('SET_UNIT_CATEGORIES', []);
    commit('SET_UNIT_TYPES', []);
    commit('SET_BUILDING_DIRECTIONS', []);
    commit('SET_FLOOR_TYPES', []);
    commit('SET_FIELD_RULES', []);
  },
};
