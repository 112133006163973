<template>
  <div>
    <v-col cols="12" class="text-right add--button">
      <v-btn
        color="primary"
        @click="openDialog('acceptVerification')"
        class="mr-4"
        v-if="permissions.includes('EDIT') && allowedActions.includes('ACCEPTED')"
      >
        {{ $t('general.accept') }}
      </v-btn>
      <v-btn
        color="error"
        @click="openDialog('rejectVerification')"
        class="mr-0"
        v-if="permissions.includes('EDIT') && allowedActions.includes('REJECTED')"
      >
        {{ $t('general.reject') }}
      </v-btn>
      <v-btn
        color="warning"
        @click="openDialog('pendingVerification')"
        class="mr-0"
        v-if="permissions.includes('EDIT') && allowedActions.includes('PENDING')"
      >
        {{ $t('general.pending') }}
      </v-btn>
    </v-col>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>
          {{ $t(`${dialogType}.title`) }}

          <v-spacer />

          <v-icon aria-label="Close" @click="showDialog = false"> mdi-close </v-icon>
        </v-card-title>

        <v-textarea
          class="pt-2 px-6 dialog--input"
          :label="$t(`${dialogType}.reason`)"
          rows="3"
          v-model="reason"
          v-if="dialogType === 'rejectVerification'"
          outlined
        ></v-textarea>

        <v-card-text
          class="pb-6 text-center"
          :class="{
            'pt-12': dialogType !== 'rejectVerification',
            'pt-0': dialogType === 'rejectVerification',
          }"
        >
          <v-btn color="error" text @click="showDialog = false">
            {{ $t(`${dialogType}.no`) }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="action(dialogType)"
            :disabled="dialogType === 'rejectVerification' && !reason"
          >
            {{ $t(`${dialogType}.yes`) }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'VerificationActionButton',
  props: {
    basePath: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    allowedActions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showDialog: false,
    dialogType: null,
    reason: '',
  }),
  methods: {
    openDialog(type) {
      this.dialogType = type;
      this.showDialog = true;
    },
    action(type) {
      if (type === 'acceptVerification') this.acceptAction();
      else if (type === 'rejectVerification') this.rejectAction();
      else if (type === 'pendingVerification') this.pendingAction();
    },
    acceptAction() {
      this.$emit('acceptAction', this.id);
    },
    rejectAction() {
      this.$emit('rejectAction', { id: this.id, reason: this.reason });
    },
    pendingAction() {
      this.$emit('pendingAction', this.id);
    },
  },
};
</script>
