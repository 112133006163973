export const state = () => ({
  basePath: '/project-inquiries',
  loading: false,
  permissions: [],
  inquiries: [],
  meta: {},
  detail: {},
  filterStatuses: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_INQUIRIES(state, payload) {
    state.inquiries = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_FILTER_STATUSES(state, payload) {
    state.filterStatuses = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.inquiries = [];
    state.meta = {};
    state.detail = {};
    state.filterStatuses = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/project_inquiries/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_INQUIRIES', result.inquiries.data);
      commit('SET_META', result.inquiries.meta);
      commit('SET_FILTER_STATUSES', result.filter_statuses);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState }, id) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/project_inquiries/' + id + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result.inquiry);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async accept({ rootState }, id) {
    await this._vm.$http.post('/api/project_inquiries/accept', {
      registration_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async reject({ rootState }, id) {
    await this._vm.$http.post('/api/project_inquiries/reject', {
      registration_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async pending({ rootState }, id) {
    await this._vm.$http.post('/api/project_inquiries/pending', {
      registration_id: id,
      url: rootState.route.path.substring(1),
    });
  },
};
