export const state = () => ({
  photoList: [],
});

export const getters = {
  formData(state) {
    const photos = JSON.parse(JSON.stringify(state.photoList));
    let data;
    if (Object.keys(photos).length > 0) {
      const uuids = photos.map(function (photo) {
        return photo.uuid;
      });

      const primaryPhoto = photos.find(function (photo) {
        return photo.isPrimary;
      });
      data = {
        primary_photo_uuid: primaryPhoto ? primaryPhoto.uuid : null,
        photo_uuids: uuids,
      };
    } else {
      data = {
        primary_photo_uuid: null,
      };
    }
    return data;
  },
};

export const mutations = {
  ADD_PHOTO(state, payload) {
    state.photoList.push(payload);
  },

  REMOVE_PHOTO(state, arrIndex) {
    state.photoList.splice(arrIndex, 1);
  },
  TOGGLE_PRIMARY_PHOTO(state, arrIndex) {
    let photo = state.photoList[arrIndex];
    if (photo.isPrimary) {
      photo.isPrimary = false;
      state.photoList[arrIndex] = photo;
    } else {
      state.photoList = state.photoList.map(function (element, index) {
        element.isPrimary = index == arrIndex;
        return element;
      });
    }
  },
  ADD_PHOTOS(state, photos) {
    photos.forEach(function (photo) {
      if (photo.photo) {
        state.photoList.push({
          isPrimary: photo.is_primary_photo,
          src: photo.photo_image,
          uuid: photo.photo.uuid,
        });
      }
    });
  },
  SET_PHOTO_LIST(state, payload) {
    state.photoList = payload;
  },
};

export const actions = {
  applyEditData({ commit }, data) {
    if (data.photo_data.project_unit_type_photos) {
      if (data.photo_data.project_unit_type_photos.length > 0) {
        commit('ADD_PHOTOS', data.photo_data.project_unit_type_photos);
      }
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_PHOTO_LIST', []);
  },
};
