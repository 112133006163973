export const state = () => ({
  formType: null,
  loading: false,
  clientUuid: null,
  name: null,
  phone: null,
  email: null,
  notes: null,
  accountHolder: null,
  accountNumber: null,
  selectedBank: null,
  banks: [],
  assignedAgent: null,
});

export const getters = {
  formData(state) {
    return {
      uuid: state.clientUuid,
      name: state.name,
      phone_number: state.phone,
      email: state.email,
      notes: state.notes,
      account_holder: state.accountHolder,
      account_number: state.accountNumber,
      bank_id: state.selectedBank != null ? state.selectedBank.id : null,
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_CLIENT_UUID(state, val) {
    state.clientUuid = val;
  },
  SET_NAME(state, val) {
    state.name = val;
  },
  SET_PHONE(state, val) {
    state.phone = val;
  },
  SET_EMAIL(state, val) {
    state.email = val;
  },
  SET_NOTES(state, val) {
    state.notes = val;
  },
  SET_ACCOUNT_HOLDER(state, val) {
    state.accountHolder = val;
  },
  SET_ACCOUNT_NUMBER(state, val) {
    state.accountNumber = val;
  },
  SET_SELECTED_BANK(state, val) {
    state.selectedBank = val;
  },
  SET_BANKS(state, val) {
    state.banks = val;
  },
  SET_ASSIGNED_AGENT(state, val) {
    state.assignedAgent = val;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/clients/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_CLIENT_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async applyBaseData({ commit }, data) {
    commit('SET_BANKS', data.banks);
  },
  async applyEditData({ commit }, data) {
    commit('SET_CLIENT_UUID', data.client.client_uuid);
    commit('SET_BANKS', data.banks);
  },
  async applyEditDataFromDetail({ commit, state }, data) {
    commit('SET_CLIENT_UUID', data.uuid);
    commit('SET_NAME', data.name);
    commit('SET_PHONE', data.phone_number);
    commit('SET_EMAIL', data.email);
    commit('SET_NOTES', data.notes);
    commit('SET_ACCOUNT_HOLDER', data.account_holder);
    commit('SET_ACCOUNT_NUMBER', data.account_number);

    if (data.bank_id) {
      const selected = state.banks.find((item) => {
        return item.id == data.bank_id;
      });
      commit('SET_SELECTED_BANK', selected ? selected : null);
    }
  },
  async assignClientToAgent({ commit, rootState }, { agentUuid, clientUuid }) {
    try {
      commit('SET_LOADING', true);
      const {
        // eslint-disable-next-line no-unused-vars
        data: { data: resultData },
      } = await this._vm.$http.post('/api/clients/assign_client_to_agent', {
        agent_uuid: agentUuid,
        client_uuid: clientUuid,
        url: rootState.route.path.substring(1),
      });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async assignAgent({ commit, rootState, state }) {
    try {
      commit('SET_LOADING', true);
      const {
        // eslint-disable-next-line no-unused-vars
        data: { data: resultData },
      } = await this._vm.$http.post('/api/clients/assign_client_to_agent', {
        agent_uuid: state.assignedAgent.uuid,
        client_uuid: state.clientUuid,
        url: rootState.route.path.substring(1),
      });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async unassignAgent({ commit, rootState, state }) {
    try {
      commit('SET_LOADING', true);
      const {
        // eslint-disable-next-line no-unused-vars
        data: { data: resultData },
      } = await this._vm.$http.post('/api/clients/unassign_client_to_agent', {
        assigned_client_id: state.assignedAgent.id,
        url: rootState.route.path.substring(1),
      });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/clients/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/clients/edit/' + state.clientUuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_CLIENT_UUID', null);
    commit('SET_NAME', null);
    commit('SET_PHONE', null);
    commit('SET_EMAIL', null);
    commit('SET_NOTES', null);
    commit('SET_ACCOUNT_HOLDER', null);
    commit('SET_ACCOUNT_NUMBER', null);
    commit('SET_SELECTED_BANK', null);
    commit('SET_BANKS', []);
    commit('SET_ASSIGNED_AGENT', null);
  },
};
