<template>
  <v-col cols="12" class="text-right add--button">
    <router-link :to="basePath + '/edit/' + id" v-if="permissions.includes('EDIT')">
      <v-btn color="success">
        {{ $t('general.edit') }}
      </v-btn>
    </router-link>
    <!--        <v-btn color="error" @click="deleteAction" class="mr-0" v-if="permissions.includes('DELETE')">-->
    <!--          {{ $t('general.delete') }}-->
    <!--        </v-btn>-->

    <base-delete-button
      :permissions="permissions"
      :id="id"
      @deleteAction="deleteAction"
    ></base-delete-button>
  </v-col>
</template>

<script>
export default {
  name: 'DetailActionButton',
  props: {
    basePath: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    deleteAction() {
      this.$emit('deleteAction', this.id);
    },
  },
};
</script>
