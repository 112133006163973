import Vue from 'vue';
import VueI18n from 'vue-i18n';

// import en from 'vuetify/lib/locale/en'
import id from 'vuetify/lib/locale/id';

Vue.use(VueI18n);

const numberFormats = {
  'id-ID': {
    currency: {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
    },
  },
};
const messages = {
  // en: {
  //   ...require('@/locales/en.json'),
  //   $vuetify: en,
  // },
  id: {
    ...require('@/locales/id.json'),
    $vuetify: id,
  },
};

export default new VueI18n({
  numberFormats,
  locale: process.env.VUE_APP_I18N_LOCALE || 'id',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'id',
  messages,
});
