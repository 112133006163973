export const state = () => ({
  basePath: '/order-management/orders',
  loading: false,
  permissions: [],
  orders: [],
  meta: {},
  statusFilters: [],
  typeFilters: [],
  salesFilters: ['Diri Sendiri'],
  totalPaidAmount: null,
  isRefundAllowed: null,
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_ORDERS(state, payload) {
    state.orders = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_STATUS_FILTERS(state, payload) {
    state.statusFilters = payload;
  },
  SET_TYPE_FILTERS(state, payload) {
    state.typeFilters = payload;
  },
  SET_SALES_FILTERS(state, payload) {
    state.salesFilters = payload;
  },
  SET_TOTAL_PAID_AMOUNT(state, payload) {
    state.totalPaidAmount = payload;
  },
  SET_IS_REFUND_ALLOWED(state, payload) {
    state.isRefundAllowed = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.orders = [];
    state.meta = {};
    state.isRefundAllowed = null;
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/orders/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_ORDERS', result.orders.data);
      commit('SET_META', result.orders.meta);
      commit('SET_STATUS_FILTERS', result.status_filters);
      commit('SET_TYPE_FILTERS', result.type_filters);
      commit('SET_TOTAL_PAID_AMOUNT', result.orders.meta.total_paid_amount);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/orders/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_ORDERS', result.data);
      commit('SET_META', result.meta);
      commit('SET_TOTAL_PAID_AMOUNT', result.meta.total_paid_amount);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async checkRefundAllowed({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      const dataInitiator = await this._vm.$http.get('/api/permission/is_page_allowed', {
        params: {
          url: `refund-management-initiator/refunds/add`,
        },
      });

      let isAllowedInitiator = dataInitiator.data.data.is_allowed;
      if (isAllowedInitiator) {
        commit('SET_IS_REFUND_ALLOWED', 'INITIATOR');
        return state.isRefundAllowed;
      }

      const dataManager = await this._vm.$http.get('/api/permission/is_page_allowed', {
        params: {
          url: `refund-management-manager/refunds/add`,
        },
      });

      let isAllowedManager = dataManager.data.data.is_allowed;
      if (isAllowedManager) {
        commit('SET_IS_REFUND_ALLOWED', 'MANAGER');
        return state.isRefundAllowed;
      }

      return state.isRefundAllowed;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
