import Vue from 'vue';

import Vuex from 'vuex';

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

// console.log('REQUIRED CONTEXT:', requireContext.keys());
const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    // console.log('PRINT NAME: ', name);
    // console.log('PRINT MODULE: ', module);
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

// export default new Vuex.Store({
//   modules
// })

export default () => {
  return new Vuex.Store({
    modules,
    state: {
      barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
      barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
      drawer: null,
    },
    mutations: {
      SET_BAR_IMAGE(state, payload) {
        state.barImage = payload;
      },
      SET_DRAWER(state, payload) {
        state.drawer = payload;
      },
    },
    actions: {},
  });
};
