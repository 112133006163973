// const requireContext = require.context('./withdrawalRequest', false, /.*\.js$/);
// const modulesTemp = requireContext
//   .keys()
//   .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
//   .reduce((modules, [name, module]) => {
//     if (module.namespaced === undefined) {
//       module.namespaced = true;
//     }
//     // if()
//
//     return { ...modules, [name]: module };
//   }, {});

// export const modules = modulesTemp;

export const state = () => ({
  basePath: '/commission-management/withdrawal-requests',
  loading: false,
  permissions: [],
  withdrawalRequests: [],
  meta: {},
  detail: {},
  receiptPhoto: null,
});
export const getters = {
  disburseFormData(state) {
    return {
      withdrawal_request_id: state.detail ? state.detail.id : null,
      receipt_photo_uuid: state.receiptPhoto ? state.receiptPhoto.uuid : null,
    };
  },
  rejectFormData(state) {
    return {
      withdrawal_request_id: state.detail ? state.detail.id : null,
    };
  },
};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_WITHDRAWAL_REQUESTS(state, payload) {
    state.withdrawalRequests = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_RECEIPT_PHOTO(state, payload) {
    state.receiptPhoto = payload;
  },
  ADD_RECEIPT_PHOTO(state, payload) {
    state.receiptPhoto = payload;
  },
  // eslint-disable-next-line no-unused-vars
  REMOVE_RECEIPT_PHOTO(state, payload) {
    state.receiptPhoto = null;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.withdrawalRequests = [];
    state.meta = {};
    state.detail = {};
    state.receiptPhoto = null;
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/withdrawal_requests/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_WITHDRAWAL_REQUESTS', result.withdrawal_requests.data);
      commit('SET_META', result.withdrawal_requests.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/withdrawal_requests/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_WITHDRAWAL_REQUESTS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState }, id) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/withdrawal_requests/' + id + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result);
      if (result.receipt_photo) {
        commit('SET_RECEIPT_PHOTO', {
          src: result.receipt_photo,
          uuid: null,
          isPrimary: false,
        });
      }
      // await dispatch('client/form/restoreInitialState', {}, { root: true });
      // await dispatch('client/form/applyBaseData', result.form.base_data, { root: true });
      // await dispatch('client/form/applyEditDataFromDetail', result.form.edit_data, { root: true });
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async disburse({ getters, dispatch }) {
    let formData = getters.disburseFormData;
    const url = '/api/withdrawal_requests/disburse';
    await dispatch('postData', { url: url, formData: formData });
  },
  async reject({ getters, dispatch }) {
    let formData = getters.rejectFormData;
    const url = '/api/withdrawal_requests/reject';
    await dispatch('postData', { url: url, formData: formData });
  },
  async postData({ commit, rootState }, { url, formData }) {
    try {
      commit('SET_LOADING', true);
      formData.url = rootState.route.path.substring(1);

      await this._vm.$http.post(url, formData);
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
