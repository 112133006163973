export function generateParams(rootState, query) {
  let params = {};
  params.url = rootState.route.path.substring(1);
  if (query) {
    return { ...params, ...query };
    // if (query.page) params.page = query.page;
    // if (query.project_uuid) params.project_uuid = query.project_uuid;
    // if (query.unit_category_id) params.unit_category_id = query.unit_category_id;
    // if (query.project_unit_type_uuid) params.project_unit_type_uuid = query.project_unit_type_uuid;
    // if (query.status_filter) params.status_filter = query.status_filter;
    // if (query.otp_filter_id || query.otp_filter_id === 0)
    //   params.otp_filter_id = query.otp_filter_id;
    // if (query.id_verif_filter_id || query.id_verif_filter_id === 0)
    //   params.id_verif_filter_id = query.id_verif_filter_id;
  } else {
    return params;
  }
}
