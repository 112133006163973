<template>
  <v-col cols="12" class="text-right add--button" v-if="permissions.includes('ADD')">
    <router-link :to="urlRouter">
      <v-btn color="success" class="mr-0">
        {{ title }}
      </v-btn>
    </router-link>
  </v-col>
</template>

<script>
export default {
  name: 'AddButton',
  props: {
    basePath: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  computed: {
    urlRouter() {
      // console.log('uhuyy', this.params);

      if (!this.params) {
        return this.basePath + '/add';
      } else {
        // return this.basePath + '/add';

        let tempUrl = this.basePath + '/add';
        let counter = 0;
        Object.keys(this.params).forEach((key) => {
          // console.log('HAAAAAA');
          console.log(key, this.params[key]);
          if (counter === 0) {
            tempUrl = `${tempUrl}?${key}=${this.params[key]}`;
          } else {
            tempUrl = `${tempUrl}&${key}=${this.params[key]}`;
          }
          counter++;
        });
        // return this.basePath + '/add';

        return tempUrl;
        // for(let i= 0; i<this.params.length; i ++){
        //   if(i==0){
        //     tempUrl = `${tempUrl}?${this.params.}`
        //   }
        // }
      }
    },
  },
};
</script>
