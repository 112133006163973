import { getFieldRule } from '@/utils/listingFormUtils'; // Adjust the path based on your project structure

const requireContext = require.context('./form', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  name: null,
  minLandSize: null,
  maxLandSize: null,
  minBuildingSize: null,
  maxBuildingSize: null,
  electricityCapacity: null,
  minBathroom: null,
  maxBathroom: null,
  minBedroom: null,
  maxBedroom: null,
  panoEmbedUrl: null,
  minPrice: null,
  maxPrice: null,
  totalFloor: null,

  furnishingStatuses: [],
  selectedFurnishingStatus: null,
  projectName: null,
  projectUuid: null,
  facilityList: [],
  allowLandSize: true,
  allowTotalFloor: true,
  fieldRules: [],
  selectedPropertyType: null,
});

export const getters = {
  facilitiesWithUnit(state) {
    return state.facilityList.filter(function (facility) {
      return facility.uom_name !== null;
    });
  },
  facilitiesWithoutUnit(state) {
    return state.facilityList.filter(function (facility) {
      return facility.uom_name === null;
    });
  },
  selectedFacilityIds(state) {
    let selectedFacilities = state.facilityList.filter(function (facility) {
      return facility.checked;
    });
    return selectedFacilities.map(function (facility) {
      return facility.id;
    });
  },
  formData(state, getters) {
    const mediaFormData = getters['media/formData'];

    const selectedFacilityIds = getters.selectedFacilityIds;
    let tempFacility = {};
    if (state.facilityList.length !== 0) {
      selectedFacilityIds.forEach((id) => {
        const selectedFacility = state.facilityList.find((data) => {
          return data.id == id;
        });

        if (selectedFacility.amount === undefined) {
          tempFacility[selectedFacility.id] = null;
        } else {
          if (!Number.isNaN(parseInt(selectedFacility.amount))) {
            tempFacility[selectedFacility.id] = parseInt(selectedFacility.amount);
          } else {
            tempFacility[selectedFacility.id] = null;
          }
        }
      });
    }
    if (Object.keys(tempFacility).length === 0) {
      tempFacility = null;
    }
    let currentFormData = {
      uuid: state.uuid,
      name: state.name,
      min_land_size: state.minLandSize,
      max_land_size: state.maxLandSize,
      min_building_size: state.minBuildingSize,
      max_building_size: state.maxBuildingSize,
      min_bathroom: state.minBathroom,
      max_bathroom: state.maxBathroom,
      min_bedroom: state.minBedroom,
      max_bedroom: state.maxBedroom,
      electricity_capacity: state.electricityCapacity,
      pano_embed_url: state.panoEmbedUrl,
      min_price: state.minPrice,
      max_price: state.maxPrice,
      total_floor: state.totalFloor,
      project_uuid: state.projectUuid,
      selected_facilities: tempFacility,
      furnishing_status_id: state.selectedFurnishingStatus
        ? state.selectedFurnishingStatus.id
        : null,
    };
    return Object.assign({}, currentFormData, mediaFormData);
  },
  totalFloorRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'total_floor');
  },
  minLandSizeRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'min_land_size');
  },
  maxLandSizeRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'max_land_size');
  },
  minBuildingSizeRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'min_building_size');
  },
  maxBuildingSizeRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'max_building_size');
  },
  minBedroomRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'min_bedroom');
  },
  maxBedroomRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'max_bedroom');
  },
  minBathroomRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'min_bathroom');
  },
  maxBathroomRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'max_bathroom');
  },
  electricalCapacityRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'electricity_capacity');
  },
  furnishingStatusRule(state) {
    return getFieldRule(state.selectedPropertyType, state.fieldRules, 'furnishing_status_id');
  },
};

export const mutations = {
  SET_PROJECT_NAME(state, uuid) {
    state.projectName = uuid;
  },
  SET_ALLOW_LAND_SIZE(state, uuid) {
    state.allowLandSize = uuid;
  },
  SET_ALLOW_TOTAL_FLOOR(state, uuid) {
    state.allowTotalFloor = uuid;
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_PROJECT_UUID(state, uuid) {
    state.projectUuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_MIN_LAND_SIZE(state, payload) {
    state.minLandSize = payload;
  },
  SET_MAX_LAND_SIZE(state, payload) {
    state.maxLandSize = payload;
  },
  SET_MIN_BUILDING_SIZE(state, payload) {
    state.minBuildingSize = payload;
  },
  SET_MAX_BUILDING_SIZE(state, payload) {
    state.maxBuildingSize = payload;
  },
  SET_MIN_BATHROOM(state, payload) {
    state.minBathroom = payload;
  },
  SET_MAX_BATHROOM(state, payload) {
    state.maxBathroom = payload;
  },
  SET_MIN_BEDROOM(state, payload) {
    state.minBedroom = payload;
  },
  SET_MAX_BEDROOM(state, payload) {
    state.maxBedroom = payload;
  },
  SET_MIN_PRICE(state, payload) {
    state.minPrice = payload;
  },
  SET_MAX_PRICE(state, payload) {
    state.maxPrice = payload;
  },
  SET_ELECTRICITY_CAPACITY(state, payload) {
    state.electricityCapacity = payload;
  },
  SET_PANO_EMBED_URL(state, payload) {
    state.panoEmbedUrl = payload;
  },
  SET_TOTAL_FLOOR(state, payload) {
    state.totalFloor = payload;
  },
  SET_SELECTED_FURNISHING_STATUS(state, payload) {
    state.selectedFurnishingStatus = payload;
  },
  SET_FURNISHING_STATUSES(state, payload) {
    state.furnishingStatuses = payload;
  },

  SET_FACILITY_LIST(state, payload) {
    state.facilityList = payload.map(function (item) {
      item.checked = false;
      if (item.uom_name !== null) {
        item.amount = null;
      }
      return item;
    });
  },

  TOGGLE_CHECK_FACILITY(state, facilityId) {
    const facility = state.facilityList.find(function (facility) {
      return facility.id == facilityId;
    });
    facility.checked = !facility.checked;

    if (!facility.checked) {
      if (facility.unit_name !== null) {
        facility.amount = null;
      }
    }
  },

  CHANGE_FACILITY_AMOUNT(state, { facilityId, amount }) {
    const facility = state.facilityList.find(function (fac) {
      return fac.id == facilityId;
    });
    facility.amount = amount;
  },

  SET_FIELD_RULES(state, payload) {
    state.fieldRules = payload;
  },

  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
};

export const actions = {
  async postData({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/project_unit_types/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  applyBaseData({ commit }, baseData) {
    commit('SET_FURNISHING_STATUSES', baseData.furnishing_statuses);
    commit('SET_FACILITY_LIST', baseData.facilities);
    commit('SET_FIELD_RULES', baseData.field_rules);
    commit('SET_SELECTED_PROPERTY_TYPE', {
      id: baseData.property_type_id,
      name: '',
    });
  },

  async applyEditData({ commit, state, dispatch }, data) {
    await dispatch('media/applyEditData', data);
    commit('SET_UUID', data.uuid);
    commit('SET_NAME', data.name);
    commit('SET_MIN_LAND_SIZE', data.min_land_size);
    commit('SET_MAX_LAND_SIZE', data.max_land_size);
    commit('SET_MIN_BUILDING_SIZE', data.min_building_size);
    commit('SET_MAX_BUILDING_SIZE', data.max_building_size);
    commit('SET_MIN_BATHROOM', data.min_bathroom);
    commit('SET_MAX_BATHROOM', data.max_bathroom);
    commit('SET_MIN_BEDROOM', data.min_bedroom);
    commit('SET_MAX_BEDROOM', data.max_bedroom);
    commit('SET_MIN_PRICE', data.min_price);
    commit('SET_MAX_PRICE', data.max_price);
    commit('SET_ELECTRICITY_CAPACITY', data.electricity_capacity);
    commit('SET_PANO_EMBED_URL', data.pano_embed_url);
    commit('SET_TOTAL_FLOOR', data.total_floor);

    for (let key in data.selected_facilities) {
      // The key is key
      // The value is obj[key]
      commit('TOGGLE_CHECK_FACILITY', key);
      if (data.selected_facilities[key] != null) {
        commit('CHANGE_FACILITY_AMOUNT', {
          facilityId: key,
          amount: parseInt(data.selected_facilities[key]),
        });
      }
    }
    if (data.furnishing_status_id) {
      const selectedFurnishingStatus = state.furnishingStatuses.find((item) => {
        return item.id == data.furnishing_status_id;
      });
      commit(
        'SET_SELECTED_FURNISHING_STATUS',
        selectedFurnishingStatus ? selectedFurnishingStatus : null,
      );
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/project_unit_types/add', {
          params: {
            url: rootState.route.path.substring(1),
            project_uuid: state.projectUuid,
          },
        });
        await dispatch('applyBaseData', resultData);
        commit('SET_PROJECT_NAME', resultData.project_name);
        await commit('SET_ALLOW_LAND_SIZE', resultData.allow_land_size);
        await commit('SET_ALLOW_TOTAL_FLOOR', resultData.allow_total_floor);
      } else {
        console.log('uuid', state.uuid);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/project_unit_types/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
        await commit('SET_PROJECT_NAME', resultData.project_name);
        await commit('SET_ALLOW_LAND_SIZE', resultData.allow_land_size);
        await commit('SET_ALLOW_TOTAL_FLOOR', resultData.allow_total_floor);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit, dispatch }) {
    console.log('restoreInitialState');
    await dispatch('media/restoreInitialState');
    commit('SET_PROJECT_NAME', null);
    commit('SET_UUID', null);
    commit('SET_PROJECT_UUID', null);
    commit('SET_NAME', null);
    commit('SET_MIN_LAND_SIZE', null);
    commit('SET_MAX_LAND_SIZE', null);
    commit('SET_MIN_BUILDING_SIZE', null);
    commit('SET_MAX_BUILDING_SIZE', null);
    commit('SET_MIN_BATHROOM', null);
    commit('SET_MAX_BATHROOM', null);
    commit('SET_MIN_BEDROOM', null);
    commit('SET_MAX_BEDROOM', null);
    commit('SET_MIN_PRICE', null);
    commit('SET_MAX_PRICE', null);
    commit('SET_ELECTRICITY_CAPACITY', null);
    commit('SET_PANO_EMBED_URL', null);
    commit('SET_TOTAL_FLOOR', null);
    commit('SET_SELECTED_FURNISHING_STATUS', null);
    commit('SET_FURNISHING_STATUSES', []);
    commit('SET_FIELD_RULES', []);
  },
};
