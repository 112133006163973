<template>
  <v-col cols="12" class="text-right filter--dropdown">
    <div>
      <v-select v-model="selectedItem" :items="items" :label="label" solo hide-details clearable>
        <template v-slot:selection="{ item }">
          {{ item }}
        </template>
      </v-select>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'DropdownFilter',
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Filter',
    },
  },
  data: () => ({
    selectedItem: null,
  }),
  watch: {
    selectedItem(val) {
      this.changeFilter(val);
    },
  },
  methods: {
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
