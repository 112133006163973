export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  name: null,
  referralCode: null,
  coinBonus: null,
  isActive: false,
});

export const getters = {
  formData(state) {
    let currentFormData = {
      uuid: state.uuid,
      name: state.name,
      referral_code: state.referralCode,
      coin_bonus: state.coinBonus,
      referral_benefit_type_id: 1,
      is_active: state.isActive,
    };
    return Object.assign({}, currentFormData);
  },
};

export const mutations = {
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_NAME(state, uuid) {
    state.name = uuid;
  },
  SET_REFERRAL_CODE(state, uuid) {
    state.referralCode = uuid;
  },
  SET_COIN_BONUS(state, uuid) {
    state.coinBonus = uuid;
  },
  SET_IS_ACTIVE(state, uuid) {
    state.isActive = uuid;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/referral_programs/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  applyBaseData({ commit }, baseData) {},

  // eslint-disable-next-line no-unused-vars
  async applyEditData({ commit, state, dispatch }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_NAME', data.name);
    commit('SET_REFERRAL_CODE', data.referral_code);
    commit('SET_COIN_BONUS', data.coin_bonus);
    commit('SET_IS_ACTIVE', data.is_active);
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        // const {
        //   data: { data: resultData },
        // } = await this._vm.$http.get('/api/referral_programs/add', {
        //   params: {
        //     url: rootState.route.path.substring(1),
        //   },
        // });
        // await dispatch('applyBaseData', resultData);
      } else {
        console.log('uuid', state.uuid);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/referral_programs/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_NAME', null);
    commit('SET_REFERRAL_CODE', null);
    commit('SET_COIN_BONUS', null);
  },
};
