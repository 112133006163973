export const state = () => ({
  formType: null,
  loading: false,
  id: null,
  name: null,
  nib: null,
  logoUrl: null,
  logoFileId: null,
  logoFile: null,
  agencies: [],
  selectedAgency: null,
  allowChangeAgency: false,
});

export const getters = {
  formData(state) {
    let currentFormData = {
      id: state.id,
      name: state.name,
      nib: state.nib,
      logo_file_id: state.logoFile ? state.logoFile.id : state.logoFileId,
      agency_id: state.selectedAgency ? state.selectedAgency.id : null,
    };
    return Object.assign({}, currentFormData);
  },
};

export const mutations = {
  SET_ID(state, uuid) {
    state.id = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_NIB(state, payload) {
    state.nib = payload;
  },
  SET_LOGO_URL(state, payload) {
    state.logoUrl = payload;
  },
  SET_LOGO_FILE_ID(state, payload) {
    state.logoFileId = payload;
  },
  SET_LOGO_FILE(state, payload) {
    state.logoFile = payload;
  },
  SET_AGENCIES(state, payload) {
    state.agencies = payload;
  },
  SET_SELECTED_AGENCY(state, payload) {
    state.selectedAgency = payload;
  },
  SET_ALLOW_CHANGE_AGENCY(state, payload) {
    state.allowChangeAgency = payload;
  },
};

export const actions = {
  postData: async function ({ state, commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      formData.action = state.formType === 'ADD' ? 'activate' : 'save';
      const url = '/api/agent_offices/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.id) {
        commit('SET_ID', responseData.id);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  applyBaseData({ commit }, baseData) {
    commit('SET_AGENCIES', baseData.agencies);
    commit('SET_ALLOW_CHANGE_AGENCY', true);
  },

  async applyEditData({ commit, state }, data) {
    commit('SET_NAME', data.name);
    commit('SET_NIB', data.nib);
    commit('SET_LOGO_URL', data.logo_url);
    commit('SET_LOGO_FILE_ID', data.logo_file_id);
    if (data.agency_id) {
      const selectedAgency = state.agencies.find((item) => {
        return item.id == data.agency_id;
      });
      commit('SET_SELECTED_AGENCY', selectedAgency ? selectedAgency : null);
      commit('SET_ALLOW_CHANGE_AGENCY', false);
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/agent_offices/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
      } else {
        console.log('id', state.id);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/agent_offices/edit/' + state.id, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit }) {
    commit('SET_ID', null);
    commit('SET_NAME', null);
    commit('SET_NIB', null);
    commit('SET_LOGO_URL', null);
    commit('SET_LOGO_FILE_ID', null);
    commit('SET_LOGO_FILE', null);
    commit('SET_AGENCIES', []);
    commit('SET_SELECTED_AGENCY', null);
    commit('SET_ALLOW_CHANGE_AGENCY', false);
  },
};
