export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  name: null,
  phone: null,
  oldEmail: null,
  email: null,
  accountHolder: null,
  accountNumber: null,
  selectedBank: null,
  banks: [],
  membership: null,
  agentType: null,
  office: null,
  areaSpecs: [],
  propSpecs: [],
  priceSpecs: [],
  cities: [],
  agentTypes: [],
  agentOffices: [],
  socialMedias: [],
  photoProfile: null,
  propertySpecList: [],
  priceSpecList: [],
  password: null,
  nib: null,
  notes: [],
  tags: [],
  noteFormType: null,
  statusId: null,
});

export const getters = {
  formData(state) {
    return {
      user_uuid: state.uuid,
      name: state.name,
      email: state.email,
      phone_number: state.phone,
      account_holder: state.accountHolder,
      account_number: state.accountNumber,
      bank_id: state.selectedBank != null ? state.selectedBank.id : null,
      agent_type_id: state.agentType != null ? state.agentType.id : null,
      agent_office_id:
        state.agentType != null && state.agentType.id === 2 && state.office != null
          ? state.office.id
          : null,
      property_specialization_ids: state.propSpecs.map((e) => e.id),
      price_specialization_ids: state.priceSpecs.map((e) => e.id),
      area_specialization_ids: state.areaSpecs.map((e) => e.id),
      password: state.password,
      nib: state.nib,
      notes: state.notes
        ? state.notes.map((e) => {
            return { text: e.text, tag_id: e.tag.id };
          })
        : [],
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_UUID(state, val) {
    state.uuid = val;
  },
  SET_NAME(state, val) {
    state.name = val;
  },
  SET_PHONE(state, val) {
    state.phone = val;
  },
  SET_OLD_EMAIL(state, val) {
    state.oldEmail = val;
  },
  SET_EMAIL(state, val) {
    state.email = val;
  },
  SET_ACCOUNT_HOLDER(state, val) {
    state.accountHolder = val;
  },
  SET_ACCOUNT_NUMBER(state, val) {
    state.accountNumber = val;
  },
  SET_SELECTED_BANK(state, val) {
    state.selectedBank = val;
  },
  SET_BANKS(state, val) {
    state.banks = val;
  },
  SET_MEMBERSHIP(state, val) {
    state.membership = val;
  },
  SET_AGENT_TYPE(state, val) {
    state.agentType = val;
  },
  SET_OFFICE(state, val) {
    state.office = val;
  },
  SET_AREA_SPECS(state, val) {
    state.areaSpecs = val;
  },
  SET_PROP_SPECS(state, val) {
    state.propSpecs = val;
  },
  SET_PRICE_SPECS(state, val) {
    state.priceSpecs = val;
  },
  SET_CITIES(state, val) {
    state.cities = val;
  },
  SET_AGENT_TYPES(state, val) {
    state.agentTypes = val;
  },
  SET_AGENT_OFFICES(state, val) {
    state.agentOffices = val;
  },
  SET_SOCIAL_MEDIAS(state, val) {
    state.socialMedias = val;
  },
  SET_PHOTO_PROFILE(state, val) {
    state.photoProfile = val;
  },
  SET_PROPERTY_SPEC_LIST(state, val) {
    state.propertySpecList = val;
  },
  SET_PRICE_SPEC_LIST(state, val) {
    state.priceSpecList = val;
  },
  SET_PASSWORD(state, val) {
    state.password = val;
  },
  SET_NIB(state, val) {
    state.nib = val;
  },

  ADD_NOTE(state, note) {
    state.notes.push({ text: note ? note.text : '', tag: note ? note.tag : null });
  },
  SET_NOTES(state, notes) {
    let temp = [];
    notes.forEach(function (element) {
      temp.push(element);
    });
    state.notes = temp;
  },
  REMOVE_NOTE(state, index) {
    if (state.notes.length > 0) {
      state.notes.splice(index, 1);
    }
  },
  INPUT_NOTE_TEXT(state, { index, text }) {
    let selectedNote = JSON.parse(JSON.stringify(state.notes))[index];
    selectedNote.text = text;
    state.notes.splice(index, 1, selectedNote);
  },
  INPUT_NOTE_TAG(state, { index, tag }) {
    let selectedNote = JSON.parse(JSON.stringify(state.notes))[index];
    selectedNote.tag = tag;
    state.notes.splice(index, 1, selectedNote);
  },
  SET_TAGS(state, val) {
    state.tags = val;
  },
  SET_NOTE_FORM_TYPE(state, val) {
    state.noteFormType = val;
  },
  SET_STATUS_ID(state, val) {
    state.statusId = val;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/beliruma_users/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async applyBaseData({ commit }, data) {
    commit('SET_BANKS', data.banks);
    commit('SET_CITIES', data.cities);
    commit('SET_AGENT_TYPES', data.agent_types);
    commit('SET_AGENT_OFFICES', data.agent_offices);
    commit('SET_SOCIAL_MEDIAS', data.social_medias);
    commit('SET_PROPERTY_SPEC_LIST', data.property_specializations);
    commit('SET_PRICE_SPEC_LIST', data.price_specializations);
    commit('SET_TAGS', data.tags);
  },
  async applyEditData({ commit, state }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_NAME', data.name);
    commit('SET_PHONE', data.phone_number);
    commit('SET_NIB', data.nib);
    commit('SET_OLD_EMAIL', data.email);
    commit('SET_EMAIL', data.email);
    commit('SET_MEMBERSHIP', data.membership);
    commit('SET_AREA_SPECS', data.area_specializations);
    commit('SET_PROP_SPECS', data.property_specializations);
    commit('SET_PRICE_SPECS', data.price_specializations);
    commit('SET_PHOTO_PROFILE', data.photo_profile_user);
    commit('SET_NOTES', data.notes);
    commit('SET_STATUS_ID', data.status_id);
    if (data.agent_type) {
      const selected = state.agentTypes.find((item) => {
        return item.name == data.agent_type;
      });
      commit('SET_AGENT_TYPE', selected ? selected : null);
    }
    if (data.agent_office) {
      const selected = state.agentOffices.find((item) => {
        return item.name == data.agent_office;
      });
      commit('SET_OFFICE', selected ? selected : null);
    }
    if (data.bank_account) {
      commit('SET_ACCOUNT_HOLDER', data.bank_account.account_holder);
      commit('SET_ACCOUNT_NUMBER', data.bank_account.account_number);

      if (data.bank_account.bank_id) {
        const selected = state.banks.find((item) => {
          return item.id == data.bank_account.bank_id;
        });
        commit('SET_SELECTED_BANK', selected ? selected : null);
      }
    }
  },
  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/beliruma_users/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/beliruma_users/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_NAME', null);
    commit('SET_PHONE', null);
    commit('SET_NIB', null);
    commit('SET_OLD_EMAIL', null);
    commit('SET_EMAIL', null);
    commit('SET_ACCOUNT_HOLDER', null);
    commit('SET_ACCOUNT_NUMBER', null);
    commit('SET_SELECTED_BANK', null);
    commit('SET_BANKS', []);
    commit('SET_MEMBERSHIP', null);
    commit('SET_AGENT_TYPE', null);
    commit('SET_OFFICE', null);
    commit('SET_AREA_SPECS', []);
    commit('SET_PROP_SPECS', []);
    commit('SET_PRICE_SPECS', []);
    commit('SET_CITIES', []);
    commit('SET_AGENT_TYPES', []);
    commit('SET_AGENT_OFFICES', []);
    commit('SET_SOCIAL_MEDIAS', []);
    commit('SET_PHOTO_PROFILE', null);
    commit('SET_PROPERTY_SPEC_LIST', []);
    commit('SET_PRICE_SPEC_LIST', []);
    commit('SET_PASSWORD', null);
    commit('SET_NIB', null);
    commit('SET_NOTES', []);
    commit('SET_TAGS', []);
  },
  async checkEmail({ rootState }, email) {
    try {
      // commit('SET_LOADING', true);
      const data = await this._vm.$http.get(
        '/api/beliruma_users/check_email_exist?email=' + email,
        {
          params: {
            url: rootState.route.path.substring(1),
          },
        },
      );
      return data.data;
    } finally {
      // commit('SET_LOADING', false);
    }
  },
  async changeAvatar({ commit, rootState }, formData) {
    try {
      commit('SET_LOADING', true);
      formData.append('url', rootState.route.path.substring(1));
      const { data } = await this._vm.$http.post('/api/beliruma_users/upload_profile', formData);
      if (data.data) {
        commit('SET_PHOTO_PROFILE', data.data);
      }
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async checkDeleteAccount({ commit, rootState }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this._vm.$http.post('/api/beliruma_users/check_delete_account', {
        uuid,
        url: rootState.route.path.substring(1),
      });
      return data.data;
      // await this._vm.$http.post('/api/beliruma_users/delete', {
      //   uuid,
      //   url: rootState.route.path.substring(1),
      // });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async delete({ commit, rootState }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this._vm.$http.post('/api/beliruma_users/delete_account', {
        uuid,
        url: rootState.route.path.substring(1),
      });
      return data.data;
      // await this._vm.$http.post('/api/beliruma_users/delete', {
      //   uuid,
      //   url: rootState.route.path.substring(1),
      // });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async addNote({ commit, dispatch }, note) {
    console.log('ADDING NOTE: ', note);
    try {
      commit('SET_LOADING', true);
      commit('ADD_NOTE', note);
      await dispatch('postData');
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async editNote({ commit, dispatch, state }, note) {
    console.log('EDITING NOTE: ', note);
    try {
      commit('SET_LOADING', true);
      for (let localNote of state.notes) {
        if (localNote.id.toString() === note.id.toString()) {
          localNote.text = note.text;
          localNote.tag = note.tag;
          break;
        }
      }
      commit('SET_NOTES', state.notes);
      await dispatch('postData');
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteNote({ commit, dispatch, state }, id) {
    console.log('DELETING NOTE: ', id);
    try {
      commit('SET_LOADING', true);
      let index = 0;
      for (let localNote of state.notes) {
        if (localNote.id.toString() === id.toString()) {
          break;
        }
        index++;
      }
      commit('REMOVE_NOTE', index);
      await dispatch('postData');
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async suspend({ commit, state, rootState }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this._vm.$http.post('/api/beliruma_users/suspend', {
        uuid: state.uuid,
        url: rootState.route.path.substring(1),
      });
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async activate({ commit, state, rootState }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this._vm.$http.post('/api/beliruma_users/activate', {
        uuid: state.uuid,
        url: rootState.route.path.substring(1),
      });
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async changePassword({ commit, state, rootState }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this._vm.$http.post('/api/beliruma_users/change_password', {
        uuid: state.uuid,
        url: rootState.route.path.substring(1),
      });
      return data.data.password;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
