<template>
  <router-view />
  <!--  <router-view v-if="!$errorHandler.error" />-->
  <!--  <error-page v-else />-->
</template>

<script>
// import ErrorPage from './components/ErrorPage.vue'
import { headConfig } from '@/config/head.js';

export default {
  head: headConfig,

  // components: { ErrorPage },
};
</script>
