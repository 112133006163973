const mapKey =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyAg8OArbG2qQg19UE5m7Zux_RHWaEe70f0'
    : 'AIzaSyCrYqjA_5Jnbm_qygdHMY7vWgCvcMsoOI0';

export const headConfig = {
  title() {
    return {
      inner: 'Beliruma.co.id',
    };
  },
  meta: [
    { name: 'description', content: 'Sistem Admin Beliruma.co.id', id: 'desc' },
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    { name: 'robots', content: 'Noindex, Nofollow' },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/img/icons/favicon-16x16.png' },
    { rel: 'manifest', href: '/manifest.json' },
    {
      rel: 'stylesheet',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css',
    },
    {
      rel: 'stylesheet',
      href: 'https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css',
      defer: true,
    },
    { rel: 'dns-prefetch', href: 'https://www.googletagmanager.com' },
    { rel: 'dns-prefetch', href: 'https://www.google-analytics.com' },
    { rel: 'dns-prefetch', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://www.google.com' },
    { rel: 'preconnect', href: 'https://www.google.co.id' },
    { rel: 'preconnect', href: 'https://maps.googleapis.com' },
    { rel: 'preconnect', href: 'https://googleads.g.doubleclick.net' },
    { rel: 'preconnect', href: 'https://api.belirumah.co', crossorigin: true },
    { rel: 'preconnect', href: 'https://stats.pusher.com' },
    { rel: 'preconnect', href: 'https://s3-ap-southeast-1.amazonaws.com' },
    {
      rel: 'preconnect',
      href: 'https://beliruma.s3-ap-southeast-1.amazonaws.com',
    },
    {
      rel: 'preload',
      href: 'https://www.google-analytics.com/analytics.js',
      as: 'script',
      type: 'text/javascript',
    },
    {
      rel: 'preload',
      href: 'https://www.googleadservices.com/pagead/conversion_async.js',
      as: 'script',
      type: 'text/javascript',
    },
    // ############### dikomen dulu untuk gtag ############
    // {
    //   rel: 'preload',
    //   href: 'https://www.googletagmanager.com/gtag/js?id=AW-828231018',
    //   as: 'script',
    //   type: 'application/javascript',
    // },
    // #####################################################
    // {
    //   rel: 'preload',
    //   href: 'https://maps.googleapis.com/maps-api-v3/api/js/38/9/util.js',
    //   as: 'script',
    //   type: 'text/javascript',
    // },
    // {
    //   rel: 'preload',
    //   href:
    //     'https://maps.googleapis.com/maps/api/js?key=' +
    //     'AIzaSyCrYqjA_5Jnbm_qygdHMY7vWgCvcMsoOI0' +
    //     '&libraries=places',
    //   as: 'script',
    //   type: 'text/javascript',
    // },
    {
      rel: 'preload',
      href: 'https://s3-ap-southeast-1.amazonaws.com/rentfix/fonts/ionicons/ionicons.ttf?v=2.0.0',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-Bold.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-Medium.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-Regular.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-SemiBold.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://fonts.googleapis.com/css?family=Lato:400,700&display=swap',
      as: 'style',
      type: 'text/css',
    },
    { rel: 'alternate', href: 'android-app://com.rentfix.www/https/rentfix.com' },
    { rel: 'alternate', href: 'ios-app://1378618675/https/rentfix.com' },
  ],
  script: [
    {
      src: 'https://maps.googleapis.com/maps/api/js?key=' + mapKey + '&libraries=places',
      defer: true,
    },
  ],
};
