export const state = () => ({
  basePath: '/registration-management/agent-verifications',
  loading: false,
  permissions: [],
  verifications: [],
  meta: {},
  detail: {},
  filterStatuses: [],
  allowedActions: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_VERIFICATIONS(state, payload) {
    state.verifications = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_FILTER_STATUSES(state, payload) {
    state.filterStatuses = payload;
  },
  SET_ALLOWED_ACTIONS(state, payload) {
    state.allowedActions = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.verifications = [];
    state.meta = {};
    state.detail = {};
    state.filterStatuses = [];
    state.allowedActions = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/agent_verifications/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_VERIFICATIONS', result.verifications.data);
      commit('SET_META', result.verifications.meta);
      commit('SET_FILTER_STATUSES', result.filter_statuses);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState }, id) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/agent_verifications/' + id + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_ALLOWED_ACTIONS', result.allowed_actions);
      commit('SET_DETAIL', result.verification);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async accept({ rootState }, id) {
    await this._vm.$http.post('/api/agent_verifications/accept', {
      agent_verification_id: id,
      url: rootState.route.path.substring(1),
    });
  },
  async reject({ rootState }, { id: id, reason: reason }) {
    await this._vm.$http.post('/api/agent_verifications/reject', {
      agent_verification_id: id,
      reason: reason,
      url: rootState.route.path.substring(1),
    });
  },
  async pending({ rootState }, id) {
    await this._vm.$http.post('/api/agent_verifications/pending', {
      agent_verification_id: id,
      url: rootState.route.path.substring(1),
    });
  },
};
