export const state = () => ({
  basePath: '/master-data/media-files',
  loading: false,
  mediaFiles: [],
  meta: {},
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_MEDIA_FILES(state, payload) {
    state.mediaFiles = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.mediaFiles = [];
    state.meta = {};
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getMediaFiles({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/media_files', {
        params: params,
      });

      let result = data.data;
      commit('SET_MEDIA_FILES', result.data);
      commit('SET_META', result.meta);
      return result;

      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async delete({}, id) {
    await this._vm.$http.post('/api/media_files/delete/' + id, {
      url: 'master-data/media-files',
    });
  },
};
