// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import App from './App.vue';
import createStore from './store/index';
import createRouter from './router';
import createHttpClient from './plugins/httpClient';
import './plugins/base';
import './plugins/chartist';
import './plugins/bootstrapVue';
import './plugins/SimpleVueValidator';
import './plugins/vee-validate';
import './plugins/VueModal';
import './plugins/VueMq';
import './plugins/VueSweetAlert2';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

import './assets/scss/app.scss';
import './registerServiceWorker';

Vue.config.productionTip = false;

const store = createStore();
import VueHead from 'vue-head';
Vue.use(VueHead);

const router = createRouter(store);
// eslint-disable-next-line no-unused-vars
const httpClient = createHttpClient(store, router);
import { sync } from 'vuex-router-sync';
sync(store, router); // done. Returns an unsync callback fn

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
// (async  () => {
//   const url = await getAPIAddress(params); //API promises
//   // the rest of the code
// })();
// const initWeb = await runInitWeb(vueIns);
