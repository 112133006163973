<template>
  <a :href="link" target="_blank" class="prod--detail-button">
    <v-icon size="32" v-text="'mdi-magnify'" />
  </a>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
