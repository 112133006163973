<template>
  <div class="delete--button">
    <v-btn
      v-show="!showIcon"
      color="error"
      @click="showDelete = true"
      class="mr-0"
      v-if="permissions.includes('DELETE')"
    >
      {{ $t('general.delete') }}
    </v-btn>
    <v-icon
      v-show="showIcon"
      size="32"
      v-text="'mdi-delete'"
      v-if="permissions.includes('DELETE')"
      @click="showDelete = true"
    />
    <v-dialog v-model="showDelete" max-width="300">
      <v-card>
        <v-card-title>
          {{ $t('delete.title') }}

          <v-spacer />

          <v-icon aria-label="Close" @click="showDelete = false"> mdi-close </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showDelete = false">
            {{ $t('delete.no') }}
          </v-btn>

          <v-btn color="primary" text @click="deleteAction">
            {{ $t('delete.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    id: {
      type: String,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showDelete: false,
  }),
  methods: {
    deleteAction() {
      this.showDelete = false;
      this.$emit('deleteAction', this.id);
    },
  },
};
</script>
