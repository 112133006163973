import Cookie from 'cookie';
import Cookies from 'js-cookie';
const baseUrl =
  process.env.VUE_APP_MODE === 'production'
    ? 'https://beliruma-inhouse.co.id'
    : process.env.VUE_APP_LOCAL_API_URL;
const apiUrl =
  process.env.VUE_APP_MODE === 'production'
    ? process.env.VUE_APP_PROD_API_URL
    : process.env.VUE_APP_LOCAL_API_URL;
const websiteUrl = 'https://belirumah.co';

export const state = () => ({
  loggedIn: false,
  user: null,
  token: null,
  remember: false,
  isLoading: false,
  locale: 'id',
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  websiteUrl: websiteUrl,
  cleaveOptionDecimal: {
    prefix: '',
    numeral: true,
    numeralPositiveOnly: true,
    noImmediatePrefix: true,
    rawValueTrimPrefix: true,
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralIntegerScale: 6,
    numeralDecimalScale: 1,
  },
  cleaveOptionCurrency: {
    numeral: true,
    numeralDecimalMark: ',',
    rawValueTrimPrefix: true,
    delimiter: '.',
    prefix: 'Rp ',
  },
  cleaveOptionNumber: {
    numeral: true,
    numeralDecimalMark: ',',
    delimiter: '.',
    prefix: '',
  },
  availabilityStatuses: [
    { id: 1, name: 'ACTIVE' },
    { id: 2, name: 'DRAFT' },
    { id: 3, name: 'NOT_ACTIVE' },
  ],
  bannerTypes: [
    { id: 1, name: 'AGENT' },
    { id: 2, name: 'USER' },
  ],
  isTakeoverList: [
    { id: 0, name: 'Tidak' },
    { id: 1, name: 'Iya' },
  ],
});
export const getters = {};
export const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_LOCALE(state, payload) {
    state.locale = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_LOGGED_IN(state, payload) {
    state.loggedIn = payload;
  },
  SET_REMEMBER(state, remember) {
    state.remember = remember;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};

export const actions = {
  async login({ commit, dispatch }, { fields, endpoint = '/api/auth/login' } = {}) {
    // Send credentials to API
    const data = await this._vm.$http.post(endpoint, fields);

    commit('SET_REMEMBER', fields.remember);

    await dispatch('updateToken', data.data.token);

    await dispatch('fetch');

    return data.data;
  },
  async updateToken({ state, commit }, token) {
    // Update token in store's state
    commit('SET_TOKEN', token);

    // $http.setToken(token, 'Bearer');
    this._vm.$http.defaults.headers.common.Authorization = `Bearer ${token}`;

    if (process.browser) {
      // ...Browser
      if (token) {
        Cookies.set('BeliRumaInhouse', token, {
          expires: state.remember ? 365 : null,
        });
      } else {
        Cookies.remove('BeliRumaInhouse');
      }
    }
  },
  async fetch({ state, commit, dispatch }, { endpoint = '/api/user' } = {}) {
    await dispatch('fetchToken');
    // Skip if there is no token set
    if (!state.token) {
      return;
    }
    // Try to get user profile
    try {
      const data = await this._vm.$http.get(endpoint);
      console.log('SEBELUM SET USER', data.data.data);
      commit('SET_USER', data.data.data);
      commit('SET_LOGGED_IN', !!data.data.data);
      Cookies.set('user', JSON.stringify(data.data.data));
    } catch (e) {
      await dispatch('resetAuth');
    }
  },
  async resetAuth({ dispatch, commit }) {
    commit('SET_USER', null);
    commit('SET_LOGGED_IN', false);
    await dispatch('updateToken', null);
  },
  async fetchToken({ dispatch }) {
    const cookieStr = process.browser ? document.cookie : null;
    const cookies = Cookie.parse(cookieStr || '') || {};
    const token = cookies.BeliRumaInhouse;
    if (token) {
      await dispatch('updateToken', token);
    }
  },
  async logout({ dispatch }, { endpoint = '/api/auth/logout' } = {}) {
    // Server side logout
    try {
      await this._vm.$http.post(endpoint);
      await dispatch('resetAuth');
    } catch (e) {
      // console.error('Error while logging out', e)
    }
    // try {
    //   await this.$fireAuth.signOut();
    // } catch (e) {
    //   // console.log("Firebase logout error: ", e);
    // }
    // Reset store
  },
  async webInit({ state, commit }) {
    let token;

    const cookieStr = document.cookie;
    const cookies = require('cookie').parse(cookieStr || '') || {};
    console.log('cookies', cookies);
    token = cookies.BeliRumaInhouse;

    const locale = cookies.locale;
    commit('SET_TOKEN', token);
    commit('SET_LOCALE', locale);

    this._vm.$http.defaults.headers.common.Authorization = `Bearer ${state.token}`;
    this._vm.$http.defaults.headers.common['Content-Language'] = locale ? locale : 'en';

    if (token) {
      Cookies.set('BeliRumaInhouse', token, {
        expires: state.remember ? 365 : null,
      });
    } else {
      Cookies.remove('BeliRumaInhouse');
    }

    Cookies.set('locale', locale ? locale : 'id', { expires: 10 });
    if (token) {
      try {
        const data = await this._vm.$http.get('/api/user');
        if (data.data.data) {
          commit('SET_USER', data.data.data);
          commit('SET_LOGGED_IN', !!data.data.data);
        }
      } catch (e) {
        console.log('masuk sini 3', e);

        Cookies.remove('BeliRumaInhouse');
        Cookies.remove('user');
        commit('SET_USER', null);
        commit('SET_LOGGED_IN', false);
      }
    }
  },
};
