export const state = () => ({
  provinces: [],
  province: null,
  cities: [],
  city: null,
  districts: [],
  district: null,
  villages: [],
  village: null,
  areas: [],
  area: null,

  openDistrict: false,
  openCity: false,
});

export const getters = {
  formData(state) {
    return {
      province_id: state.province ? state.province.id : state.province,
      city_id: state.city ? state.city.id : state.city,
      // district_id: state.district ? state.district.id : state.district,
      // village_id: state.village ? state.village.id : state.village,
      area_id: state.area ? state.area.id : state.area,
    };
  },
};

export const mutations = {
  SET_OPEN_DISTRICT(state, payload) {
    state.openDistrict = payload;
  },
  SET_OPEN_CITY(state, payload) {
    state.openCity = payload;
  },
  SET_PROVINCES(state, payload) {
    state.provinces = payload;
  },
  SET_PROVINCE(state, payload) {
    state.province = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_DISTRICT(state, payload) {
    state.district = payload;
  },
  SET_VILLAGE(state, payload) {
    state.village = payload;
  },
  SET_AREA(state, payload) {
    state.area = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_VILLAGES(state, payload) {
    state.villages = payload;
  },
  SET_AREAS(state, payload) {
    state.areas = payload;
  },
};

export const actions = {
  changeProvince({ commit, state }, payload) {
    if (payload) {
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty('id')) {
        commit('SET_PROVINCE', payload);
      } else {
        const pId = payload;
        let selectedProvince = state.provinces.find(function (item) {
          return item.id == pId;
        });
        if (!selectedProvince) {
          selectedProvince = null;
        }
        commit('SET_PROVINCE', selectedProvince);
      }
      commit('SET_OPEN_CITY', true);
    } else {
      commit('SET_PROVINCE', null);
    }
  },
  changeCity({ commit, state }, payload) {
    if (payload) {
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty('id')) {
        commit('SET_CITY', payload);
      } else {
        const cId = payload;
        let selectedCity = state.cities.find(function (item) {
          return item.id == cId;
        });
        if (!selectedCity) {
          selectedCity = null;
        }
        commit('SET_CITY', selectedCity);
      }
      commit('SET_OPEN_DISTRICT', true);
    } else {
      commit('SET_CITY', null);
    }
  },
  changeDistrict({ commit, state }, payload) {
    if (payload) {
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty('id')) {
        commit('SET_DISTRICT', payload);
      } else {
        const dId = payload;
        let selectedDistrict = state.districts.find(function (item) {
          return item.id == dId;
        });
        if (!selectedDistrict) {
          selectedDistrict = null;
        }
        commit('SET_DISTRICT', selectedDistrict);
      }
    } else {
      commit('SET_DISTRICT', null);
    }
  },
  changeVillage({ commit, state }, payload) {
    if (payload) {
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty('id')) {
        commit('SET_VILLAGE', payload);
      } else {
        const vId = payload;
        let selectedVillage = state.villages.find(function (item) {
          return item.id == vId;
        });
        if (!selectedVillage) {
          selectedVillage = null;
        }
        commit('SET_VILLAGE', selectedVillage);
      }
    } else {
      commit('SET_VILLAGE', null);
    }
  },
  changeArea({ commit, state }, payload) {
    if (payload) {
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty('id')) {
        commit('SET_AREA', payload);
      } else {
        const vId = payload;
        let selectedArea = state.areas.find(function (item) {
          return item.id == vId;
        });
        if (!selectedArea) {
          selectedArea = null;
        }
        commit('SET_AREA', selectedArea);
      }
    } else {
      commit('SET_AREA', null);
    }
  },

  async setRegionalData({ commit, dispatch }, requestData) {
    commit('SET_PROVINCES', requestData.provinces);
    commit('SET_CITIES', requestData.cities);
    commit('SET_DISTRICTS', requestData.districts);
    commit('SET_VILLAGES', requestData.villages);
    commit('SET_AREAS', requestData.areas);
    await dispatch('changeProvince', requestData.province);
    await dispatch('changeCity', requestData.city);
    await dispatch('changeDistrict', requestData.district);
    await dispatch('changeVillage', requestData.village);
    await dispatch('changeArea', requestData.area);
  },

  restoreInitialState({ commit }) {
    commit('SET_PROVINCES', []);
    commit('SET_CITIES', []);
    commit('SET_DISTRICTS', []);
    commit('SET_VILLAGES', []);
    commit('SET_AREAS', []);
    commit('SET_PROVINCE', null);
    commit('SET_CITY', null);
    commit('SET_DISTRICT', null);
    commit('SET_VILLAGE', null);
    commit('SET_AREA', null);
  },
  getProvinces({ commit }) {
    this._vm.$http.get('/api/v1/getAllProvince', {}).then((response) => {
      commit('SET_PROVINCES', response.data);
    });
  },

  async getCities({ state, commit, dispatch }) {
    commit('SET_CITIES', []);
    commit('SET_DISTRICTS', []);
    commit('SET_VILLAGES', []);
    commit('SET_AREAS', []);
    dispatch('changeCity', null);
    dispatch('changeDistrict', null);
    dispatch('changeVillage', null);
    dispatch('changeArea', null);
    await this._vm.$http
      .get('/api/regional/get_city_by_province', {
        params: {
          province_id: state.province.id,
        },
      })
      .then((result) => {
        commit('SET_CITIES', result.data.data);
      });
  },

  async getDistricts({ state, commit, dispatch }) {
    commit('SET_DISTRICTS', []);
    commit('SET_VILLAGES', []);
    dispatch('changeDistrict', null);
    dispatch('changeVillage', null);
    await this._vm.$http
      .get('/api/regional/get_district_by_city', {
        params: {
          city_id: state.city.id,
        },
      })
      .then((result) => {
        commit('SET_DISTRICTS', result.data.data);
      });
  },

  async getVillages({ state, commit, dispatch }) {
    commit('SET_VILLAGES', []);
    dispatch('changeVillage', null);
    await this._vm.$http
      .get('/api/regional/get_village_by_district', {
        params: {
          district_id: state.district.id,
        },
      })
      .then((result) => {
        commit('SET_VILLAGES', result.data.data);
      });
  },

  async getAreas({ state, commit, dispatch }) {
    commit('SET_AREAS', []);
    dispatch('changeArea', null);
    await this._vm.$http
      .get('/api/regional/get_area_by_city', {
        params: {
          city_id: state.city.id,
        },
      })
      .then((result) => {
        commit('SET_AREAS', result.data.data);
      });
  },
};
