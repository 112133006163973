export const state = () => ({
  formType: null,
  loading: false,
  id: null,
  name: null,
  defaultAgentOffice: null,
  agentOffices: [],
});

export const getters = {
  formData(state) {
    let currentFormData = {
      id: state.id,
      name: state.name,
      default_agent_office_id: state.defaultAgentOffice ? state.defaultAgentOffice.id : null,
    };
    return Object.assign({}, currentFormData);
  },
};

export const mutations = {
  SET_ID(state, uuid) {
    state.id = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_DEFAULT_AGENT_OFFICE(state, payload) {
    state.defaultAgentOffice = payload;
  },
  SET_AGENT_OFFICES(state, payload) {
    state.agentOffices = payload;
  },
};

export const actions = {
  postData: async function ({ state, commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      formData.action = state.formType === 'ADD' ? 'activate' : 'save';
      const url = '/api/agencies/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.id) {
        commit('SET_ID', responseData.id);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  applyBaseData({ commit }, baseData) {
    commit('SET_AGENT_OFFICES', baseData.agent_offices);
  },

  async applyEditData({ commit, state }, data) {
    commit('SET_NAME', data.name);
    if (data.default_agent_office_id) {
      const selectedAgentOffice = state.agentOffices.find((item) => {
        return item.id == data.default_agent_office_id;
      });
      commit('SET_DEFAULT_AGENT_OFFICE', selectedAgentOffice ? selectedAgentOffice : null);
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/agencies/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
      } else {
        console.log('id', state.id);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/agencies/edit/' + state.id, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit }) {
    commit('SET_ID', null);
    commit('SET_NAME', null);
    commit('SET_DEFAULT_AGENT_OFFICE', null);
    commit('SET_AGENT_OFFICES', []);
  },
};
