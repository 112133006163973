export const state = () => ({
  formType: null,
  loading: false,
  id: null,
  name: null,
  province: null,
  city: null,
  provinces: [],
  cities: [],
});

export const getters = {
  formData(state) {
    let currentFormData = {
      id: state.id,
      name: state.name,
      city_id: state.city,
    };
    return Object.assign({}, currentFormData);
  },
};

export const mutations = {
  SET_ID(state, uuid) {
    state.id = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_PROVINCE(state, payload) {
    state.province = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_PROVINCES(state, payload) {
    state.provinces = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
};

export const actions = {
  postData: async function ({ state, commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      formData.action = state.formType === 'ADD' ? 'activate' : 'save';
      const url = '/api/areas/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.id) {
        commit('SET_ID', responseData.id);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  applyBaseData({ commit }, baseData) {
    commit('SET_PROVINCES', baseData.provinces);
  },

  async applyEditData({ commit, dispatch }, data) {
    commit('SET_NAME', data.name);
    commit('SET_CITY', data.regional_data.city);
    commit('SET_PROVINCE', data.regional_data.province);
    dispatch('getCityByProvince', data.regional_data.province);
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/areas/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
      } else {
        console.log('id', state.id);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/areas/edit/' + state.id, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getCityByProvince({ commit }, id) {
    const response = await this._vm.$http.get('/api/regional/get_city_by_province/', {
      params: {
        province_id: id,
      },
    });
    commit('SET_CITIES', response.data.data);
  },

  async restoreInitialState({ commit }) {
    commit('SET_ID', null);
    commit('SET_NAME', null);
    commit('SET_PROVINCE', null);
    commit('SET_CITY', null);
    commit('SET_PROVINCES', []);
    commit('SET_CITIES', []);
  },
};
