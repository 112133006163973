export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  name: null,
  email: null,
  password: null,
  group: null,
  groups: [],
});

export const getters = {
  formData(state) {
    return {
      name: state.name,
      email: state.email,
      password: state.password,
      group_id: state.group != null ? state.group.id : null,
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_UUID(state, val) {
    state.uuid = val;
  },
  SET_NAME(state, val) {
    state.name = val;
  },
  SET_EMAIL(state, val) {
    state.email = val;
  },
  SET_PASSWORD(state, val) {
    state.password = val;
  },
  SET_GROUP(state, val) {
    state.group = val;
  },
  SET_GROUPS(state, val) {
    state.groups = val;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/admin_users';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async applyBaseData({ commit }, data) {
    commit('SET_GROUPS', data.groups);
  },
  async applyEditData({ commit, state }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_NAME', data.name);
    commit('SET_EMAIL', data.email);
    commit('SET_PASSWORD', data.password);
    if (data.group_id) {
      const selected = state.groups.find((item) => {
        return item.id == data.group_id;
      });
      commit('SET_GROUP', selected ? selected : null);
    }
  },
  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/admin_users', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/admin_users/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_NAME', null);
    commit('SET_EMAIL', null);
    commit('SET_PASSWORD', null);
    commit('SET_GROUP', null);
    commit('SET_GROUPS', []);
  },
};
