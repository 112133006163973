<template>
  <v-col cols="12" class="text-right detail--button" v-if="permissions.includes('VIEW')">
    <router-link :to="basePath + '/' + id">
      <v-btn color="success" class="mr-0">
        {{ buttonText ? buttonText : $t('general.detail') }}
      </v-btn>
    </router-link>
  </v-col>
</template>

<script>
export default {
  name: 'DetailButton',
  props: {
    basePath: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    buttonText: {
      type: String,
    },
  },
};
</script>
