const requireContext = require.context('./agency', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/master-data/agencies',
  loading: false,
  permissions: [],
  agencies: [],
  meta: {},
  detail: {},
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_AGENCIES(state, payload) {
    state.agencies = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.agencies = [];
    state.meta = {};
    state.detail = {};
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/agencies/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_AGENCIES', result.agencies.data);
      commit('SET_META', result.agencies.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/agencies/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_AGENCIES', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState, dispatch }, id) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/agencies/' + id, {
        params: params,
      });

      let result = data.data.data;
      await dispatch('form/restoreInitialState');
      await dispatch('form/applyBaseData', result.base_data);
      await dispatch('form/applyEditData', result.edit_data);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async delete({}, id) {
    await this._vm.$http.post('/api/agencies/delete/' + id, {
      url: 'master-data/agencies',
    });
  },
};
