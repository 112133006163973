<template>
  <v-col cols="12" class="text-right filter--dropdown">
    <div style="margin-bottom: 4px">
      <date-picker
        v-model="selectedDate"
        @input="changeFilter"
        value-type="format"
        :placeholder="placeholder ?? 'Filter Tanggal'"
        range
      ></date-picker>
    </div>
  </v-col>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'DateFilter',
  props: ['placeholder'],
  components: {
    DatePicker,
  },
  data: () => ({
    selectedDate: null,
  }),
  methods: {
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
