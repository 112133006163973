<template>
  <div>
    <v-col cols="12" class="text-center detail--button">
      <v-btn
        color="success"
        @click="openDialog('accept')"
        style="margin-left: 8px !important; margin-right: 8px !important"
        v-if="permissions.includes('EDIT') && allowedActions.includes('ACCEPTED')"
      >
        {{ $t('general.accept') }}
      </v-btn>
      <v-btn
        color="error"
        @click="openDialog('reject')"
        style="margin-left: 8px !important; margin-right: 8px !important"
        v-if="permissions.includes('EDIT') && allowedActions.includes('REJECTED')"
      >
        {{ $t('general.reject') }}
      </v-btn>
      <v-btn
        color="success"
        @click="openDialog('claim')"
        style="margin-left: 8px !important; margin-right: 8px !important"
        v-if="permissions.includes('EDIT') && allowedActions.includes('CLAIM')"
      >
        {{ $t('general.claim') }}
      </v-btn>
    </v-col>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>
          {{ $t(`registrant.${dialogType}.title`) }}

          <v-spacer />

          <v-icon aria-label="Close" @click="showDialog = false"> mdi-close </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showDialog = false">
            {{ $t(`registrant.${dialogType}.no`) }}
          </v-btn>

          <v-btn color="primary" text @click="action(dialogType)">
            {{ $t(`registrant.${dialogType}.yes`) }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RegistrantActionButton',
  props: {
    basePath: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    allowedActions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showDialog: false,
    dialogType: null,
  }),
  methods: {
    openDialog(type) {
      this.dialogType = type;
      this.showDialog = true;
    },
    action(type) {
      if (type === 'accept') this.acceptAction();
      else if (type === 'reject') this.rejectAction();
      else if (type === 'claim') this.claimAction();
    },
    acceptAction() {
      this.$emit('acceptAction', this.id);
      this.showDialog = false;
    },
    rejectAction() {
      this.$emit('rejectAction', this.id);
      this.showDialog = false;
    },
    claimAction() {
      this.$emit('claimAction', this.id);
      this.showDialog = false;
    },
  },
};
</script>
