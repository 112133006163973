export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  name: null,
  phone: null,
  email: null,
  accountHolder: null,
  accountNumber: null,
  selectedBank: null,
  banks: [],
  membership: null,
  agentType: null,
  office: null,
  areaSpecs: [],
  propSpecs: [],
  priceSpecs: [],
});

export const getters = {
  formData(state) {
    return {
      user_uuid: state.uuid,
      name: state.name,
      phone_number: state.phone,
      account_holder: state.accountHolder,
      account_number: state.accountNumber,
      bank_id: state.selectedBank != null ? state.selectedBank.id : null,
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_UUID(state, val) {
    state.uuid = val;
  },
  SET_NAME(state, val) {
    state.name = val;
  },
  SET_PHONE(state, val) {
    state.phone = val;
  },
  SET_EMAIL(state, val) {
    state.email = val;
  },
  SET_ACCOUNT_HOLDER(state, val) {
    state.accountHolder = val;
  },
  SET_ACCOUNT_NUMBER(state, val) {
    state.accountNumber = val;
  },
  SET_SELECTED_BANK(state, val) {
    state.selectedBank = val;
  },
  SET_BANKS(state, val) {
    state.banks = val;
  },
  SET_MEMBERSHIP(state, val) {
    state.membership = val;
  },
  SET_AGENT_TYPE(state, val) {
    state.agentType = val;
  },
  SET_OFFICE(state, val) {
    state.office = val;
  },
  SET_AREA_SPECS(state, val) {
    state.areaSpecs = val;
  },
  SET_PROP_SPECS(state, val) {
    state.propSpecs = val;
  },
  SET_PRICE_SPECS(state, val) {
    state.priceSpecs = val;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/agent_members/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async applyBaseData({ commit }, data) {
    commit('SET_BANKS', data.banks);
  },
  async applyEditData({ commit, state }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_NAME', data.name);
    commit('SET_PHONE', data.phone_number);
    commit('SET_EMAIL', data.email);
    commit('SET_MEMBERSHIP', data.membership);
    commit('SET_AGENT_TYPE', data.agent_type);
    commit('SET_OFFICE', data.agent_office);
    commit('SET_AREA_SPECS', data.area_specializations);
    commit('SET_PROP_SPECS', data.property_specializations);
    commit('SET_PRICE_SPECS', data.price_specializations);
    if (data.bank_account) {
      commit('SET_ACCOUNT_HOLDER', data.bank_account.account_holder);
      commit('SET_ACCOUNT_NUMBER', data.bank_account.account_number);

      if (data.bank_account.bank_id) {
        const selected = state.banks.find((item) => {
          return item.id == data.bank_account.bank_id;
        });
        commit('SET_SELECTED_BANK', selected ? selected : null);
      }
    }
  },
  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/agent_members/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/agent_members/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_NAME', null);
    commit('SET_PHONE', null);
    commit('SET_EMAIL', null);
    commit('SET_ACCOUNT_HOLDER', null);
    commit('SET_ACCOUNT_NUMBER', null);
    commit('SET_SELECTED_BANK', null);
    commit('SET_BANKS', []);
    commit('SET_MEMBERSHIP', null);
    commit('SET_AGENT_TYPE', null);
    commit('SET_OFFICE', null);
    commit('SET_AREA_SPECS', []);
    commit('SET_PROP_SPECS', []);
    commit('SET_PRICE_SPECS', []);
  },
};
