export const state = () => ({
  basePath: '/promo-management/promo-orders',
  loading: false,
  permissions: [],
  promoOrders: [],
  meta: {},
  detail: {},
  statusFilters: ['ON_GOING', 'COMPLETED', 'CANCELED'],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_PROMO_ORDERS(state, payload) {
    state.promoOrders = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.promoOrders = [];
    state.meta = {};
    state.detail = {};
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      let params = generateParams(rootState, query);
      if (!('status' in params)) {
        params.status = null;
      }
      console.log('PARAMS CONTENT: ', params);
      const data = await this._vm.$http.post('/api/promo_orders/get_init_data', params);

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_PROMO_ORDERS', result.promoOrders.data);
      commit('SET_META', result.promoOrders.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      let params = generateParams(rootState, query);
      if (!('status' in params)) {
        params.status = null;
      }
      const data = await this._vm.$http.post('/api/promo_orders/get_data', params);

      let result = data.data;
      commit('SET_PROMO_ORDERS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
