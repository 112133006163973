export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  order: null,
  refundAmount: null,
  banks: [],
  selectedBank: null,
  accountHolder: null,
  accountNumber: null,
  refundReason: null,
  orderOptions: [],
});

export const getters = {
  formData(state) {
    let currentFormData = {
      order_uuid: state.order ? state.order.uuid : null,
      bank_id: state.selectedBank ? state.selectedBank.id : null,
      account_holder: state.accountHolder,
      account_number: state.accountNumber,
      refund_reason: state.refundReason,
      refund_amount: state.refundAmount,
    };
    return Object.assign({}, currentFormData);
  },
};

export const mutations = {
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ORDER(state, uuid) {
    state.order = uuid;
  },
  SET_REFUND_AMOUNT(state, uuid) {
    state.refundAmount = uuid;
  },
  SET_BANKS(state, uuid) {
    state.banks = uuid;
  },
  SET_SELECTED_BANK(state, uuid) {
    state.selectedBank = uuid;
  },
  SET_ACCOUNT_HOLDER(state, uuid) {
    state.accountHolder = uuid;
  },
  SET_ACCOUNT_NUMBER(state, uuid) {
    state.accountNumber = uuid;
  },
  SET_REFUND_REASON(state, uuid) {
    state.refundReason = uuid;
  },
  SET_ORDER_OPTIONS(state, uuid) {
    state.orderOptions = uuid;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/refund_managers/create';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  applyBaseData({ commit }, baseData) {
    commit('SET_BANKS', baseData.banks);
    if (baseData.pre_select) {
      commit('SET_ORDER', baseData.pre_select.order);
      commit('SET_REFUND_AMOUNT', baseData.pre_select.refund_amount);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async applyEditData({ commit, state, dispatch }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_NAME', data.name);
  },

  async fetchBaseData({ commit, dispatch, state, rootState }, uuid) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/refund_managers/add', {
          params: {
            url: rootState.route.path.substring(1),
            order_uuid: uuid ? uuid : rootState.route.params.uuid,
          },
        });
        await dispatch('applyBaseData', resultData);
        await dispatch('getOrders', null);
      } else {
        console.log('uuid', state.uuid);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/refund_managers/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getOrders({ commit }, query) {
    try {
      commit('SET_LOADING', true);

      const data = await this._vm.$http.get('/api/orders/get_data', {
        params: {
          url: 'transaction-management/orders',
          q: query,
          status_filter: 'Selesai'
        },
      });
      let result = data.data;
      commit('SET_ORDER_OPTIONS', result.data);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_ORDER', null);
    commit('SET_REFUND_AMOUNT', null);
    commit('SET_BANKS', []);
    commit('SET_SELECTED_BANK', null);
    commit('SET_ACCOUNT_HOLDER', null);
    commit('SET_ACCOUNT_NUMBER', null);
    commit('SET_REFUND_REASON', null);
    commit('SET_ORDER_OPTIONS', []);
  },
};
