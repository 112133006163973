export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async changePassword({ commit }, formData) {
    const response = await this._vm.$http.post('/api/change-password', formData);
    if (response.data.error) {
      return response.data;
    } else {
      commit('global/SET_USER', response.data.data, { root: true });
    }
    return response;
  },
};
