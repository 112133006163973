export const state = () => ({
  formType: null,
  loading: false,
  leadUuid: null,
  source: null,
  sources: [],
  assignedAgent: null,
  fromCatalogAgent: false,
  catalogAgent: null,
  client: null,
  clientAgentStatus: null,
});

export const getters = {
  formDataLeads(state) {
    return {
      lead_uuid: state.leadUuid,
      catalog_agent_uuid:
        state.fromCatalogAgent && state.catalogAgent != null ? state.catalogAgent.uuid : null,
      source: state.source,
    };
  },
  formDataAssignedAgent(state) {
    return {
      lead_uuid: state.leadUuid,
      user_uuid: state.assignedAgent != null ? state.assignedAgent.uuid : null,
    };
  },
  formDataClient(state) {
    let data = {
      lead_uuid: state.leadUuid,
    };
    if (state.client != null) {
      if (state.client.uuid) data['client_uuid'] = state.client.uuid;
      data['name'] = state.client.name;
      data['phone_number'] = state.client.phone_number;
      data['email'] = state.client.email;
      data['notes'] = state.client.notes;
      data['account_holder'] = state.client.account_holder;
      data['account_number'] = state.client.account_number;
      data['bank_id'] = state.client.bank_id;
    }
    return data;
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_LEAD_UUID(state, val) {
    state.leadUuid = val;
  },
  SET_SOURCE(state, val) {
    state.source = val;
  },
  SET_SOURCES(state, val) {
    state.sources = val;
  },
  SET_ASSIGNED_AGENT(state, val) {
    state.assignedAgent = val;
  },
  SET_FROM_CATALOG_AGENT(state, val) {
    state.fromCatalogAgent = val;
  },
  SET_CATALOG_AGENT(state, val) {
    state.catalogAgent = val;
  },
  SET_CLIENT(state, val) {
    state.client = val;
  },
  SET_CLIENT_AGENT_STATUS(state, val) {
    state.clientAgentStatus = val;
  },
};

export const actions = {
  async postData({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formDataLeads;
      const url = '/api/leads/update';
      formData.url = rootState.route.path.substring(1);

      await this._vm.$http.post(url, formData);
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async applyBaseData({ commit }, data) {
    commit('SET_LEAD_UUID', data.lead_uuid);
    commit('SET_SOURCES', data.lead_sources);
  },

  async applyEditData({ commit }, data) {
    commit('SET_LEAD_UUID', data.lead.uuid);
    commit('SET_SOURCE', data.lead.source);
    commit('SET_SOURCES', data.lead_sources);
    commit('SET_CLIENT_AGENT_STATUS', data.client_agent_status);
    if (data.lead.assigned_agent != null) {
      commit('SET_ASSIGNED_AGENT', data.lead.assigned_agent.data);
    } else {
      commit('SET_ASSIGNED_AGENT', null);
    }
    if (data.lead.catalog_agent != null) {
      commit('SET_FROM_CATALOG_AGENT', data.lead.catalog_agent.data != null);
      commit('SET_CATALOG_AGENT', data.lead.catalog_agent.data);
    } else {
      commit('SET_FROM_CATALOG_AGENT', false);
      commit('SET_CATALOG_AGENT', null);
    }
    if (data.lead.client != null) {
      commit('SET_CLIENT', data.lead.client.data);
    } else {
      commit('SET_CLIENT', null);
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        // commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.post('/api/leads/create', {
          url: rootState.route.path.substring(1),
        });
        await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        dispatch('project/getData', {}, { root: true });
        dispatch('agent/getList', { verified_otp_only: true }, { root: true });
        dispatch('client/getData', {}, { root: true });
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/leads/edit/' + state.leadUuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async assignAgent({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formDataAssignedAgent;
      const url = '/api/leads/assign_agent';
      formData.url = rootState.route.path.substring(1);

      await this._vm.$http.post(url, formData);
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addClient({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formDataClient;
      const url = '/api/leads/create_update_leads_client';
      formData.url = rootState.route.path.substring(1);

      await this._vm.$http.post(url, formData);
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  restoreInitialState({ commit }) {
    commit('SET_LEAD_UUID', null);
    commit('SET_SOURCE', null);
    commit('SET_SOURCES', []);
    commit('SET_ASSIGNED_AGENT', null);
    commit('SET_FROM_CATALOG_AGENT', false);
    commit('SET_CATALOG_AGENT', null);
    commit('SET_CLIENT', null);
  },
};
