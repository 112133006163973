const requireContext = require.context('./transaction', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  basePath: '/transaction-management/transactions',
  loading: false,
  permissions: [],
  transactions: [],
  meta: {},
  statusFilters: [],
  reviewFilters: [],
  detail: {},
  unit: {},
  purchaseDocument: {},
  agent: {},
  owner: {},
  client: {},
  commission: {},
  billings: [],
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_TRANSACTIONS(state, payload) {
    state.transactions = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_STATUS_FILTERS(state, payload) {
    state.statusFilters = payload;
  },
  SET_REVIEW_FILTERS(state, payload) {
    state.reviewFilters = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_UNIT(state, payload) {
    state.unit = payload;
  },
  SET_PURCHASE_DOCUMENT(state, payload) {
    state.purchaseDocument = payload;
  },
  SET_AGENT(state, payload) {
    state.agent = payload;
  },
  SET_OWNER(state, payload) {
    state.owner = payload;
  },
  SET_CLIENT(state, payload) {
    state.client = payload;
  },
  SET_COMMISSION(state, payload) {
    state.commission = payload;
  },
  SET_BILLINGS(state, payload) {
    state.billings = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.transactions = [];
    state.meta = {};
    state.detail = {};
    state.unit = {};
    state.purchaseDocument = {};
    state.agent = {};
    state.owner = {};
    state.client = {};
    state.commission = {};
    state.billings = [];
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/transactions/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_TRANSACTIONS', result.transactions.data);
      commit('SET_META', result.transactions.meta);
      commit('SET_STATUS_FILTERS', result.status_filters);
      commit('SET_REVIEW_FILTERS', result.review_filters);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/transactions/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_TRANSACTIONS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getDetailInitData({ commit, rootState, dispatch }, uuid) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, {});
      const data = await this._vm.$http.get('/api/transactions/' + uuid + '/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_DETAIL', result);
      commit('SET_UNIT', result.project_unit);
      commit('SET_PURCHASE_DOCUMENT', result.purchase_document);
      commit('SET_AGENT', result.agent);
      commit('SET_OWNER', result.listing_owner_client);
      commit('SET_CLIENT', result.payer_client);
      commit('SET_COMMISSION', result.commission);
      commit('SET_BILLINGS', result.billings);
      await dispatch('transaction/form/restoreInitialState', {}, { root: true });
      await dispatch('transaction/form/applyEditData', result, { root: true });
      await dispatch('billing/form/restoreInitialState', {}, { root: true });
      await dispatch('billing/form/applyBaseData', result, { root: true });
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
