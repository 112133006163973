<template>
  <router-link :to="urlRouter" class="edit--button">
    <v-icon size="32" v-text="'mdi-pencil'" v-if="permissions.includes('EDIT')" />
  </router-link>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    basePath: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  computed: {
    urlRouter() {
      // console.log('uhuyy', this.params);

      if (!this.params) {
        return this.basePath + '/edit/' + this.id;
      } else {
        // return this.basePath + '/edit';

        let tempUrl = this.basePath + '/edit/' + this.id;
        let counter = 0;
        Object.keys(this.params).forEach((key) => {
          // console.log('HAAAAAA');
          console.log(key, this.params[key]);
          if (counter === 0) {
            tempUrl = `${tempUrl}?${key}=${this.params[key]}`;
          } else {
            tempUrl = `${tempUrl}&${key}=${this.params[key]}`;
          }
          counter++;
        });
        // return this.basePath + '/add';

        return tempUrl;
        // for(let i= 0; i<this.params.length; i ++){
        //   if(i==0){
        //     tempUrl = `${tempUrl}?${this.params.}`
        //   }
        // }
      }
    },
  },
};
</script>
