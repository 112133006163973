export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  settlementMethods: [],
  bookingFeePrice: null,
  bookingFeeType: null,
  unitPrice: null,
  selectedSettlementMethod: null,
  dpInstallmentAmount: null,
  dpPrice: null,
  cashInstallmentAmount: null,
  purchaseDocumentPhotos: [],
  commissionPhotos: [],
});

export const getters = {
  transactionFormData(state) {
    return {
      uuid: state.uuid,
      unit_price: state.unitPrice,
      settlement_method_id:
        state.selectedSettlementMethod != null ? state.selectedSettlementMethod.id : null,
      dp_installment_amount: state.dpInstallmentAmount,
      dp_price: state.dpPrice,
      cash_installment_amount: state.cashInstallmentAmount,
    };
  },
  statusFormData(state) {
    return {
      transaction_uuid: state.uuid,
    };
  },
  commissionFormData(state) {
    return {
      transaction_uuid: state.uuid,
      photo_uuids: state.commissionPhotos.map(function (photo) {
        return photo.uuid;
      }),
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_UNIT_PRICE(state, payload) {
    state.unitPrice = payload;
  },
  SET_SETTLEMENT_METHODS(state, payload) {
    state.settlementMethods = payload;
  },
  SET_SELECTED_SETTLEMENT_METHOD(state, payload) {
    state.selectedSettlementMethod = payload;
  },
  SET_BOOKING_FEE_PRICE(state, payload) {
    state.bookingFeePrice = payload;
  },
  SET_BOOKING_FEE_TYPE(state, payload) {
    state.bookingFeeType = payload;
  },
  SET_DP_INSTALLMENT_AMOUNT(state, payload) {
    state.dpInstallmentAmount = payload;
  },
  SET_DP_PRICE(state, payload) {
    state.dpPrice = payload;
  },
  SET_CASH_INSTALLMENT_AMOUNT(state, payload) {
    state.cashInstallmentAmount = payload;
  },
  SET_PURCHASE_DOCUMENT_PHOTOS(state, payload) {
    state.purchaseDocumentPhotos = payload;
  },
  SET_COMMISSION_PHOTOS(state, payload) {
    state.commissionPhotos = payload;
  },
  ADD_COMMISSION_PHOTO(state, payload) {
    state.commissionPhotos.push(payload);
  },
  REMOVE_COMMISSION_PHOTO(state, arrIndex) {
    state.commissionPhotos.splice(arrIndex, 1);
  },
};

export const actions = {
  async updateTransaction({ getters, dispatch }) {
    let formData = getters.transactionFormData;
    const url = '/api/transactions/update';
    await dispatch('postData', { url: url, formData: formData });
  },
  async finish({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/transactions/complete';
    await dispatch('postData', { url: url, formData: formData });
  },
  async cancel({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/transactions/cancel';
    await dispatch('postData', { url: url, formData: formData });
  },
  async acceptPurchaseDoc({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/transactions/accept_purchase_doc';
    await dispatch('postData', { url: url, formData: formData });
  },
  async rejectPurchaseDoc({ getters, dispatch }) {
    let formData = getters.statusFormData;
    const url = '/api/transactions/reject_purchase_doc';
    await dispatch('postData', { url: url, formData: formData });
  },
  async setCommissionToWithdrawable({ getters, dispatch }) {
    let formData = getters.commissionFormData;
    const url = '/api/transactions/set_commission_to_withdrawable';
    await dispatch('postData', { url: url, formData: formData });
  },
  async postData({ commit, rootState }, { url, formData }) {
    try {
      commit('SET_LOADING', true);
      formData.url = rootState.route.path.substring(1);

      await this._vm.$http.post(url, formData);
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  applyBaseData({ commit }, baseData) {
    commit('SET_SETTLEMENT_METHODS', baseData.settlement_methods);
  },

  async applyEditData({ commit, state }, data) {
    commit('SET_UUID', data.uuid);
    commit('SET_UNIT_PRICE', data.unit_price);
    commit('SET_SETTLEMENT_METHODS', data.settlement_methods);
    commit('SET_BOOKING_FEE_PRICE', data.booking_fee_price);
    commit('SET_BOOKING_FEE_TYPE', data.booking_fee_type);
    commit('SET_DP_INSTALLMENT_AMOUNT', data.dp_installment_amount);
    commit('SET_DP_PRICE', data.dp_price);
    commit('SET_CASH_INSTALLMENT_AMOUNT', data.cash_installment_amount);
    commit(
      'SET_PURCHASE_DOCUMENT_PHOTOS',
      data.purchase_document ? data.purchase_document.photos : [],
    );

    if (data.settlement_method_id) {
      const selected = state.settlementMethods.find((item) => {
        return item.id == data.settlement_method_id;
      });
      commit('SET_SELECTED_SETTLEMENT_METHOD', selected ? selected : null);
    }
  },

  restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_UNIT_PRICE', null);
    commit('SET_SETTLEMENT_METHODS', []);
    commit('SET_SELECTED_SETTLEMENT_METHOD', null);
    commit('SET_BOOKING_FEE_PRICE', null);
    commit('SET_BOOKING_FEE_TYPE', null);
    commit('SET_DP_INSTALLMENT_AMOUNT', null);
    commit('SET_DP_PRICE', null);
    commit('SET_CASH_INSTALLMENT_AMOUNT', null);
    commit('SET_PURCHASE_DOCUMENT_PHOTOS', []);
    commit('SET_COMMISSION_PHOTOS', []);
  },
};
