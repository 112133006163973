import i18n from '../../i18n.js';

export const state = () => ({
  menus: [],
  isPageAllowed: false,
});
export const getters = {};
export const mutations = {
  SET_MENUS(state, payload) {
    state.menus = payload;
  },
  SET_IS_ALLOWED_PAGE(state, payload) {
    state.isPageAllowed = payload;
  },
};

export const actions = {
  async getMenus({ commit, dispatch }) {
    const data = await this._vm.$http.get('/api/get_menu');

    let menus = data.data.data;
    for (let menu of menus) {
      menu = await dispatch('transformMenu', menu);
    }
    menus = await dispatch('defaultMenu', menus);
    commit('SET_MENUS', menus);

    return menus;
  },
  async transformMenu({ dispatch }, menu) {
    menu.title = menu.name;
    menu.to = menu.url;
    if (menu.children.length > 0) {
      menu.children = menu.children[0];
      for (let child of menu.children) {
        let splitUrl = child.url.split('/');
        menu.group = '/' + splitUrl[0];
        splitUrl.shift();
        child.url = splitUrl.join('/');
        child = await dispatch('transformMenu', child);
      }
    } else {
      delete menu.children;
    }

    // Brute force to fix link for project-inquiries or mortgage-requests
    if (menu.url === 'project-inquiries' || menu.url === 'mortgage-requests') {
      menu.to = '/' + menu.url;
    }

    return menu;
  },
  // eslint-disable-next-line no-empty-pattern
  async defaultMenu({}, menus) {
    menus.unshift({
      icon: 'mdi-view-dashboard',
      title: i18n.t('dashboard'),
      to: '/',
    });
    menus.push(
      {
        icon: 'mdi-account',
        title: i18n.t('user'),
        to: '/user-profile',
      },
      // {
      //   title: i18n.t('rtables'),
      //   icon: 'mdi-clipboard-outline',
      //   to: '/tables/regular-tables',
      // },
      // {
      //   title: i18n.t('typography'),
      //   icon: 'mdi-format-font',
      //   to: '/components/typography',
      // },
      // {
      //   title: i18n.t('icons'),
      //   icon: 'mdi-chart-bubble',
      //   to: '/components/icons',
      // },
      // {
      //   title: i18n.t('google'),
      //   icon: 'mdi-map-marker',
      //   to: '/maps/google-maps',
      // },
      // {
      //   title: i18n.t('notifications'),
      //   icon: 'mdi-bell',
      //   to: '/components/notifications',
      // },
    );
    return menus;
  },
  async isAllowedPage({ commit }, url) {
    const data = await this._vm.$http.get('/api/permission/is_page_allowed', {
      params: {
        url: url,
      },
    });

    let isAllowedPage = data.data.data.is_allowed;
    commit('SET_IS_ALLOWED_PAGE', isAllowedPage);

    return isAllowedPage;
  },
};
