import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Cleave from 'vue-cleave-component';

const requireComponent = require.context('@/components/base', true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig);
  Vue.component(`Cleave`, Cleave);
});
